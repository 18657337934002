var FacetedFilters_1;
import { __awaiter, __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import Collapsible, { ClassNames as CollapsibleClassNames } from '@slickdeals/blueprint-twig/javascript/components/collapsible';
import Icon from '@slickdeals/blueprint-twig/javascript/components/icon';
import IconStrategy from '@slickdeals/blueprint-twig/javascript/strategies/indicator/iconStrategy';
import { Animations } from '@slickdeals/blueprint-twig/javascript/utilities/animation';
import { isMobile } from '@slickdeals/blueprint-twig/javascript/utilities/experience';
import { isMobileBreakpoint, isTabletBreakpoint } from '@slickdeals/blueprint-twig/javascript/utilities/breakpoints';
import Button, { ClassNames as ButtonClassNames } from '@slickdeals/blueprint-twig/javascript/components/button';
import { MediatedEvents as OverlayMediatedEvents, name as overlayName } from '@slickdeals/blueprint-twig/javascript/components/overlay';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import Mediator from '@slickdeals/blueprint-twig/javascript/core/mediator';
import '@slickdeals/blueprint-twig/javascript/components/checkbox';
import '@slickdeals/blueprint-twig/javascript/components/unorderedList';
import '../../scss/patterns/facetedFilters.scss';
export const name = 'FacetedFilters';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-facetedFilters";
    ClassNames["Active"] = "bp-p-facetedFilters--active";
    ClassNames["JavaScript"] = "js-facetedFilters";
    ClassNames["CollapsibleIndicator"] = "js-collapsibleIndicator";
    ClassNames["FilterButton"] = "js-facetedFilters_filterButton";
    ClassNames["ResetAllFiltersButton"] = "js-factedFilters_resetAllButton";
    ClassNames["CancelButton"] = "js-facetedFilters_cancelButton";
    ClassNames["ApplyButton"] = "js-facetedFilters_applyButton";
    ClassNames["Checkbox"] = "bp-c-checkbox";
    ClassNames["PriceInput"] = "bp-p-facetedFilters_priceInput";
    ClassNames["Form"] = "bp-p-facetedFilters_form";
    ClassNames["HeaderContent"] = "bp-c-collapsible_headerContent";
})(ClassNames || (ClassNames = {}));
let FacetedFilters = FacetedFilters_1 = class FacetedFilters extends BlueprintComponent {
    constructor(overlay, options) {
        super(name, options);
        this.overlay = overlay;
        this.options = options;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield BlueprintComponent.loadElement(name, this.options);
            Mediator.addListener(overlayName, OverlayMediatedEvents.Hide, this.closeSidebar.bind(this));
            Mediator.addListener(overlayName, OverlayMediatedEvents.Click, this.overlay.hide.bind(this.overlay));
            this.form = this.element.querySelector(`.${ClassNames.Form}`);
            this.initCollapsibleSections();
            // On non-mobile experience, form submits any time its state is updated
            this.isMobileExperience = (yield isMobile()) || isMobileBreakpoint();
            if (!this.isMobileExperience) {
                this.form.addEventListener('change', this.handleFormChange.bind(this));
            }
            this.isTabletExperience = false;
            // Check for tablet experience if page passes option to support it
            if (this.options.tabletExperience) {
                this.isTabletExperience = yield isTabletBreakpoint();
            }
            // Set reset button which resets all filter options
            this.resetFiltersButton = document.querySelector(`.${ClassNames.ResetAllFiltersButton}`);
            this.resetFiltersButton.addEventListener('click', this.handleResetAll.bind(this));
            // Set filter button which opens the sidebar on mobile web
            this.filterButton = document.querySelector(`.${ClassNames.FilterButton}`);
            this.filterButton.addEventListener('click', this.openSidebar.bind(this));
            // Set cancel button
            this.cancelButton = this.element.querySelector(`.${ClassNames.CancelButton}`);
            this.cancelButton.addEventListener('click', this.closeSidebar.bind(this));
            // Set apply button
            this.applyButton = this.element.querySelector(`.${ClassNames.ApplyButton}`);
            this.applyButton.addEventListener('click', this.handleApplyFilters.bind(this));
        });
    }
    /**
     * Counts how many inputs are checked/non-empty
     * @param filter Node of the collapsible component which contains a group of filters
     * @returns Number of inputs selected/ filled in
     */
    static calculateSelected(filter) {
        let numberChecked = 0;
        numberChecked += Array.from(filter.querySelectorAll(`.${ClassNames.Checkbox}`)).filter(element => element.checked).length;
        numberChecked += Array.from(filter.querySelectorAll(`.${ClassNames.PriceInput}`)).filter(element => element.value !== '').length;
        return numberChecked;
    }
    /**
     * Removes extra spaces in header
     * @param filter Node of the collapsible component which contains a group of filters
     */
    static removeHeaderSpace(filter) {
        filter.querySelector(`.${ClassNames.HeaderContent} `).innerText.trim();
    }
    /**
     * Initialize all collapsible components inside of sidebar with their indicator icon
     */
    initCollapsibleSections() {
        // Get collapsible ids for all collapsible sections that exist in this element
        const collapsibleSections = Array.from(this.element.querySelectorAll(`.${CollapsibleClassNames.JavaScript}`));
        collapsibleSections.forEach(collapsible => {
            // Gets the collapsible id
            const collapsibleId = collapsible.id;
            // Gets the indicator container within the collapsible
            const indicatorContainer = collapsible.querySelector(`.${ClassNames.CollapsibleIndicator}`);
            // If collapsible is closed to begin with, update the inactiveIconName
            const currentCollapsible = document.getElementById(collapsibleId);
            // Since these will be inside a form, we need to prevent them from being default type="submit" buttons
            currentCollapsible.querySelector(`.${ButtonClassNames.Default}`).setAttribute('type', 'button');
            const selectedFilter = this.openFilters(collapsibleId);
            // Creates the indicator to be used by visibility toggle
            const indicatorStrategy = new IconStrategy({
                element: indicatorContainer,
                inactiveIconName: selectedFilter ? 'arrowUp' : 'arrowDown',
                animation: Animations.Flip,
                defaultState: false,
            });
            // Sets the options for collapsible
            const collapsibleOptions = {
                id: collapsibleId,
                indicatorStrategy,
                expanded: selectedFilter,
            };
            // Creates a new TS instance for collapsibleInstance
            new Collapsible(collapsibleOptions);
            const filterGroup = this.element.querySelector(`#${collapsibleId}`);
            FacetedFilters_1.removeHeaderSpace(filterGroup);
            this.injectCounters(filterGroup);
            this.addOptionCounters(filterGroup);
        });
    }
    /**
     * Resets all inputs in form to their unchecked/empty state
     */
    handleResetAll() {
        const checkBoxes = Array.from(this.form.querySelectorAll(`.${ClassNames.Checkbox}`));
        checkBoxes.forEach(checkbox => checkbox.removeAttribute('checked'));
        const priceInputs = Array.from(this.form.querySelectorAll(`.${ClassNames.PriceInput}`));
        priceInputs.forEach(input => input.setAttribute('value', ''));
        this.form.submit();
        this.closeSidebar();
    }
    /**
     * Submits form when form state changes (ex. checkbox checked/unchecked)
     * @param event Event that fires when form state changes.
     */
    handleFormChange(event) {
        // Do not submit form if element firing change event is from price range inputs
        const formInput = event.target;
        if (formInput.classList.contains(`${ClassNames.PriceInput}`)) {
            return;
        }
        if (this.isMobileExperience || this.isTabletExperience) {
            // Do not submit form if on mobile. User should click apply filters instead.
            return;
        }
        this.form.submit();
    }
    /**
     * On mobile web, applies all selected filters and closes the sidebar
     */
    handleApplyFilters() {
        this.form.submit();
        this.closeSidebar();
    }
    /**
     * On mobile web, opens the sidebar
     */
    openSidebar() {
        this.element.classList.add(`${ClassNames.Active}`);
        if (!this.overlay.isVisible()) {
            this.overlay.show();
        }
    }
    /**
     * On mobile web, closes the sidebar
     */
    closeSidebar() {
        this.element.classList.remove(`${ClassNames.Active}`);
        if (this.overlay.isVisible()) {
            this.overlay.hide();
        }
    }
    /**
     *
     * Checks to see if input has been entered into checkbox or price number
     * @param selectedFilter id of filter category (e.g. size, colors)
     * @returns True if checkbox is checked or price filter contains a number. Else false.
    */
    openFilters(selectedFilter) {
        const collapsibleFilter = this.element.querySelector(`#${selectedFilter}`);
        const facet = collapsibleFilter.querySelector('[data-expanded]');
        const isExpanded = facet.dataset.expanded === '1';
        if (isExpanded) {
            return true;
        }
        const filters = Array.from(collapsibleFilter.querySelectorAll(`.${ClassNames.Checkbox}`));
        const isOneCheckboxChecked = filters.some(checkboxElement => checkboxElement.checked);
        if (isOneCheckboxChecked) {
            return true;
        }
        return false;
    }
    /**
     * Creates a counter with event handler and adds it to the collapsible header component.
     * @param filter Node of the collapsible component which contains a group of filters
     */
    injectCounters(filter) {
        // create button element here using button component
        const numSelected = FacetedFilters_1.calculateSelected(filter);
        if (numSelected === 0) {
            return;
        } // don't show when no filters are selected
        const newButton = Button.createElement(numSelected.toString(), []);
        newButton.appendChild(Icon.createElement({ name: 'close' }));
        newButton.classList.add(ButtonClassNames.JavaScript);
        newButton.addEventListener('click', this.handleCounterClick.bind(this, filter));
        filter.querySelector(`.${ClassNames.HeaderContent}`).insertAdjacentElement('beforeend', newButton);
    }
    /**
     * Removes and re-creates the counter button
     * @param filter Node of the collapsible component which contains a group of filters
     */
    onFilterChange(filter) {
        const counter = filter.querySelector(`.${ClassNames.HeaderContent} .${ButtonClassNames.JavaScript}`);
        if (counter !== null) {
            counter.remove();
        }
        this.injectCounters(filter);
    }
    /**
     * Bind event handlers to each input in filter group
     * @param filter Node of the collapsible component which contains a group of filters
     */
    addOptionCounters(filter) {
        filter.querySelectorAll(`.${ClassNames.Checkbox}, .${ClassNames.PriceInput}`)
            .forEach(element => {
            element.addEventListener('change', this.onFilterChange.bind(this, filter));
        });
    }
    /**
     * Resets a group of filters to their unchecked/empty state
     * @param filter Node of the collapsible component which contains a group of filters
     */
    handleCounterClick(filter) {
        Array.from(filter.querySelectorAll(`.${ClassNames.Checkbox}`)).forEach(checkbox => {
            const noParamReassign = checkbox;
            noParamReassign.checked = false;
        });
        Array.from(filter.querySelectorAll(`.${ClassNames.PriceInput}`)).forEach(input => {
            const noParamReassign = input;
            noParamReassign.value = '';
        });
        filter.querySelector(`.${ClassNames.HeaderContent} .${ButtonClassNames.JavaScript}`).remove();
        if (this.isMobileExperience || this.isTabletExperience) {
            return;
        }
        this.form.submit();
    }
};
FacetedFilters = FacetedFilters_1 = __decorate([
    injectable(name, Object.assign({}, Injector.createDependency(overlayName, InjectorScope.Singleton)))
], FacetedFilters);
export default FacetedFilters;
BlueprintComponent.instantiate(name);
