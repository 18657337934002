var DealCard_1;
import { __awaiter, __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import DealCardLabelService from '@slickdeals/blueprint-twig/javascript/services/dealCardLabelService';
import { name as RegistryName } from '@slickdeals/blueprint-twig/javascript/queues/jobQueue/jobRegistry';
import { name as SubscriberName } from '@slickdeals/blueprint-twig/javascript/queues/jobQueue/jobSubscriber';
import { ExecutionFrequencies, generateTopic, JobContext } from '@slickdeals/blueprint-twig/javascript/queues/jobContext';
import { ClassNames as CardClassNames } from '../components/card';
import CashbackTag, { Modifiers as CashbackTagModifiers, ClassNames as CashbackTagClassNames } from './cashbackTag';
import '@slickdeals/blueprint-twig/javascript/components/button';
import '@slickdeals/blueprint-twig/javascript/components/linkableButton';
import '@slickdeals/blueprint-twig/javascript/patterns/dealImage';
import '@slickdeals/blueprint-twig/javascript/components/icon';
import '@slickdeals/blueprint-twig/javascript/patterns/storeLink';
import './votingThumbsPopup';
import '../../scss/patterns/dealCard.scss';
/** The name of the module */
const name = 'DealCard';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-dealCard";
    ClassNames["CashbackContainer"] = "js-dealCard_cashbackContainer";
})(ClassNames || (ClassNames = {}));
/** Data attributes that are available on the cards */
export var DataAttributes;
(function (DataAttributes) {
    DataAttributes["PostedAt"] = "postedAt";
})(DataAttributes || (DataAttributes = {}));
export const impressionTopic = generateTopic(`${name}_impressions`);
/** Class for handling logic inside of a deal card */
let DealCard = DealCard_1 = class DealCard extends CatalogItem {
    constructor(labelService, registry, subscriber, options) {
        super(options);
        this.labelService = labelService;
        this.registry = registry;
        this.subscriber = subscriber;
        this.options = options;
    }
    /** Initializes the component */
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            this.element = yield CatalogItem.loadElement(name, this.options);
            _super.init.call(this);
            // Send to registry to determine whether we dispatch job or not
            this.registry.add(new JobContext({
                topic: impressionTopic,
                durable: false,
                jobKey: `${this.options.threadId}_${this.options.page}_${this.options.moduleId}_${this.options.indexInModule}`,
                schedule: [
                    {
                        replays: 1,
                        executionFrequency: ExecutionFrequencies.EverySession,
                    },
                ],
            }));
            this.initHeader();
            if (this.options.thirdPartyPixel) {
                if (typeof window.merchantPixelTrackingControllerReady === 'undefined') {
                    // register listener, merchantPixelTrackingController is not ready
                    document.addEventListener('merchantPixelTrackingControllerReady', () => {
                        this.fireTrackingPixelEvent();
                    }, true);
                }
                else {
                    // controller is ready, fire event.
                    this.fireTrackingPixelEvent();
                }
            }
        });
    }
    fireTrackingPixelEvent() {
        const event = new CustomEvent('thirdPartyPixelTrackingEvent', {
            detail: {
                merchant: this.options.thirdPartyPixel,
            },
        });
        document.dispatchEvent(event);
    }
    injectCashbackOffer(offerText, availableTypes) {
        // There's already been a decision on what cashback rate to show
        if (this.element.querySelector(`.${CashbackTagClassNames.Default}`)) {
            return;
        }
        const cashbackContainer = this.element.querySelector(`.${ClassNames.CashbackContainer}`);
        if (!cashbackContainer) {
            return;
        }
        const cashbackTag = CashbackTag.createElement(offerText, {
            modifiers: [CashbackTagModifiers.FlatLeft],
            useCashbackActivationModal: true,
        });
        if (availableTypes) {
            cashbackTag.classList.add(`${CashbackTagClassNames.Default}--${availableTypes}`);
        }
        cashbackContainer.append(cashbackTag);
        cashbackTag.style.display = '';
    }
    /** Initializes the card header */
    initHeader() {
        // May or may not exist if server content is provided
        let header = this.element.querySelector(`.${CardClassNames.JavaScriptHeader}`);
        // If it's not present
        if (!header) {
            const newHeader = document.createElement('header');
            newHeader.classList.add(CardClassNames.JavaScriptHeader, CardClassNames.Header);
            this.element.prepend(newHeader);
            header = newHeader;
        }
        const label = this.labelService.getLabel(this.element);
        if (!label) {
            return;
        }
        header.appendChild(label);
    }
    /**
     * Create a DealCard instance from an already-present DOM element using data attributes
     *
     * @param dealCardElement Deal Card Element
     */
    static createFromDataAttributes(dealCardElement) {
        const options = {
            id: dealCardElement.id,
            threadId: dealCardElement.dataset.threadId,
            moduleId: dealCardElement.dataset.moduleId,
            indexInModule: dealCardElement.dataset.indexInModule,
            page: dealCardElement.dataset.page,
            cashbackOffer: dealCardElement.dataset.cashbackOffer,
            storeId: parseInt(dealCardElement.dataset.storeId, 10),
            thirdPartyPixel: dealCardElement.dataset.thirdPartyPixel,
        };
        // Create and return the Tooltip instance
        return new DealCard_1(new DealCardLabelService(), Injector.get(RegistryName, InjectorScope.Singleton), Injector.get(SubscriberName, InjectorScope.Singleton), options);
    }
};
DealCard = DealCard_1 = __decorate([
    injectable(name),
    catalogItem(name, {
        instantiate: {
            fromDom: true,
            fromMutation: true,
            fromHydrationQueue: false,
        },
    })
], DealCard);
export default DealCard;
