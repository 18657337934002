import { __awaiter, __decorate } from "tslib";
import Button, { modifiers as ButtonModifiers } from '@slickdeals/blueprint-twig/javascript/components/button';
import '@slickdeals/blueprint-twig/javascript/components/icon';
import { mediatable } from '@slickdeals/blueprint-twig/javascript/decorators/mediatable';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import '../../scss/patterns/readMoreButton.scss';
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import { name as breakpointObserverName } from '@slickdeals/durango/dist/javascript/utilities/breakpointObserver';
import { getDesktopBreakpoint, getMobileBreakpoint, getTabletBreakpoint, Breakpoints, isMobileBreakpoint, isDesktopBreakpoint, } from '@slickdeals/blueprint-twig/javascript/utilities/breakpoints';
import { stateable } from '@slickdeals/durango/dist/javascript/decorators/stateable';
export const name = 'ReadMoreButton';
/** Classnames for this module */
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-readMoreButton";
    ClassNames["JavaScript"] = "js-readMoreButton";
    ClassNames["Text"] = "bp-p-readMoreButton_text";
    ClassNames["TextHide"] = "bp-p-readMoreButton_text--hide";
    ClassNames["TextShow"] = "bp-p-readMoreButton_text--show";
    ClassNames["ButtonElement"] = "bp-p-readMoreButton_button";
    ClassNames["ButtonShow"] = "bp-p-readMoreButton_button--show";
    ClassNames["Target"] = "js-readMore_target";
})(ClassNames || (ClassNames = {}));
/** Mediated events triggered by this module */
export var MediatedEvents;
(function (MediatedEvents) {
    MediatedEvents["Click"] = "click";
})(MediatedEvents || (MediatedEvents = {}));
/** Dependencies for injection */
export const dependencies = Object.assign({}, Injector.createDependency(breakpointObserverName, InjectorScope.Singleton));
let ReadMoreButton = class ReadMoreButton extends CatalogItem {
    /**
     * @param options Data and options to initialize the component with
     * @param breakpointObserver BreakpointObserver instance
     */
    constructor(breakpointObserver, options) {
        super(options);
        this.breakpointObserver = breakpointObserver;
        this.options = options;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = (yield CatalogItem.loadElement(name, this.options));
            // if user adds classname.target to their text reference that
            this.text = this.element.querySelector(`.${ClassNames.Target}`);
            // otherwise reference the text containing div
            if (!this.text) {
                this.text = this.element.querySelector(`.${ClassNames.Text}`);
            }
            const lineHeight = parseInt(window.getComputedStyle(this.text).getPropertyValue('line-height'), 10);
            const fontSize = parseInt(window.getComputedStyle(this.text).getPropertyValue('font-size'), 10);
            this.initState({
                lineHeight,
                fontSize,
                numberOfLines: this.options.linesShown,
                enableOnThisBreakpoint: this.options.enableOnThisBreakpoint,
                truncHeight: lineHeight * this.options.linesShown,
                expanded: this.options.expanded,
            });
            const { enableOnThisBreakpoint, expanded } = this.getState();
            this.initReadMoreButton();
            if (expanded) {
                this.expand();
            }
            else {
                this.contract();
                this.showButton();
            }
            if (enableOnThisBreakpoint) {
                this.initBreakpointObservers();
                return;
            }
            // observe container dimensions
            new ResizeObserver(this.buttonVisibility.bind(this)).observe(this.text);
        });
    }
    initReadMoreButton() {
        this.readMoreButton = Button.createElement(this.options.buttonShowText ? this.options.buttonShowText : 'Read More', [ButtonModifiers.link]);
        this.readMoreButton.classList.add(ClassNames.ButtonElement);
        this.readMoreButton.classList.add(ClassNames.ButtonShow);
        this.readMoreButton.addEventListener(MediatedEvents.Click, this.readMoreOnClick.bind(this));
        this.textToChange = this.readMoreButton.firstChild;
        this.element.appendChild(this.readMoreButton);
    }
    initBreakpointObservers() {
        // in case you only want the button to appear on mobile and not on tablet
        if (this.getState().enableOnThisBreakpoint === Breakpoints.Mobile) {
            this.breakpointObserver.observe(this.enableReadMoreButton.bind(this), {
                breakpoints: [getMobileBreakpoint()],
            });
            this.breakpointObserver.observe(this.disableReadMoreButton.bind(this), {
                breakpoints: [getTabletBreakpoint(), getDesktopBreakpoint()],
            });
            if (isMobileBreakpoint()) {
                this.enableReadMoreButton();
            }
            else {
                this.disableReadMoreButton();
            }
            return;
        }
        // appear on tablet and mobile
        this.breakpointObserver.observe(this.enableReadMoreButton.bind(this), {
            breakpoints: [getMobileBreakpoint(), getTabletBreakpoint()],
        });
        this.breakpointObserver.observe(this.disableReadMoreButton.bind(this), {
            breakpoints: [getDesktopBreakpoint()],
        });
        if (isDesktopBreakpoint()) {
            this.disableReadMoreButton();
        }
        else {
            this.enableReadMoreButton();
        }
    }
    /** Dynamically adjusts button visibility based on width of container and detecting of text overflow */
    buttonVisibility() {
        const overflowHeight = this.text.scrollHeight;
        const height = this.text.offsetHeight;
        const { truncHeight } = this.getState();
        // detects overflow
        if (overflowHeight > height) {
            this.showButton();
            return;
        }
        if (this.text.classList.contains(ClassNames.TextShow)) {
            if (overflowHeight === truncHeight) {
                this.hideButton();
                return;
            }
            // display button
            this.showButton();
            return;
        }
        this.hideButton();
    }
    showButton() {
        this.readMoreButton.classList.toggle(ClassNames.ButtonShow, true);
    }
    hideButton() {
        this.readMoreButton.classList.toggle(ClassNames.ButtonShow, false);
    }
    readMoreOnClick() {
        if (this.getState().expanded) {
            this.contract();
            return;
        }
        this.expand();
    }
    /** Show read more button for this breakpoint */
    enableReadMoreButton() {
        this.showButton();
        const { expanded } = this.getState();
        if (expanded) {
            this.expand();
            return;
        }
        this.contract();
    }
    /** Hide read more button component outside of this breakpoint */
    disableReadMoreButton() {
        this.hideButton();
        this.expand();
    }
    expand() {
        this.text.classList.toggle(ClassNames.TextHide, false);
        this.text.classList.toggle(ClassNames.TextShow, true);
        this.text.style.maxHeight = 'unset';
        this.textToChange.nodeValue = 'Read Less';
        this.setState({ expanded: true });
    }
    contract() {
        const { truncHeight, numberOfLines } = this.getState();
        this.text.classList.toggle(ClassNames.TextHide, true);
        this.text.classList.toggle(ClassNames.TextShow, false);
        this.text.style.maxHeight = `${truncHeight.toString()}px`;
        this.text.style.webkitLineClamp = numberOfLines.toString();
        this.textToChange.nodeValue = 'Read More';
        this.setState({ expanded: false });
    }
};
ReadMoreButton = __decorate([
    catalogItem(name),
    injectable(name, dependencies),
    stateable(name),
    mediatable(name, Object.values(MediatedEvents))
], ReadMoreButton);
export default ReadMoreButton;
