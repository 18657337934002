import ComponentRegistry from '@slickdeals/durango/dist/javascript/core/componentRegistry';
import { classNameWithModifier } from '@slickdeals/durango/dist/javascript/utilities/css';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import Tooltip, { TooltipSize, TooltipPosition, } from '@slickdeals/blueprint-twig/javascript/components/tooltip';
import { Positions } from '@slickdeals/durango/dist/javascript/utilities/positioning';
import { name as cashbackTagActivationModalName, ClassNames as cashbackTagActivationModalClassNames, } from './cashbackTagActivationModal';
import '../../scss/patterns/cashbackTag.scss';
/** The name of the module */
export const name = 'CashbackTag';
export const dataRole = 'cashbackTag';
/** Class names for the component */
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-cashbackTag";
    ClassNames["TooltipContentWrapper"] = "bp-c-tooltip_contentWrapper";
    ClassNames["JavaScript"] = "js-cashbackTag";
})(ClassNames || (ClassNames = {}));
/** Available modifiers for element creation */
export var Modifiers;
(function (Modifiers) {
    Modifiers["FoldedEdge"] = "foldedEdge";
    Modifiers["FlatLeft"] = "flatLeft";
})(Modifiers || (Modifiers = {}));
/**
 * TODO: refactor this component into a more traditional CatalogItem
 */
class CashbackTag extends BlueprintComponent {
    static onClick() {
        const modalElement = document.querySelector(`.${cashbackTagActivationModalClassNames.Default}`);
        if (!modalElement) {
            return;
        }
        const modal = ComponentRegistry.get(modalElement, cashbackTagActivationModalName);
        if (!modal) {
            return;
        }
        modal.show();
    }
    /**
       * Create a Cashback Tag HTML element
       *
       * @param rebatePercentage Rebate label to display
       * @param options Creation options
       */
    static createElement(rebatePercentage, options = {}) {
        // Create the base element
        const element = document.createElement('span');
        // Add data role attribute for easy lookup
        element.dataset.role = dataRole;
        // Add base class name
        element.classList.add(ClassNames.Default);
        element.style.display = 'none';
        // Add any modifiers requested
        if (options.modifiers && options.modifiers.length) {
            options.modifiers.forEach((modifier) => {
                element.classList.add(classNameWithModifier(ClassNames.Default, modifier));
            });
        }
        // Add text label
        const textNode = document.createTextNode(`${rebatePercentage}`);
        element.appendChild(textNode);
        if (options.useCashbackActivationModal) {
            element.addEventListener('click', CashbackTag.onClick);
        }
        else if (options.tooltip) {
            // If options.tooltip is an HTMLElement or Text
            let tooltipContent = options.tooltip;
            // If options.tooltip is a string
            if (typeof options.tooltip === 'string') {
                tooltipContent = document.createTextNode(options.tooltip);
            }
            const tooltipWrapper = document.createElement('div');
            tooltipWrapper.classList.add(`${ClassNames.TooltipContentWrapper}`);
            tooltipWrapper.appendChild(tooltipContent);
            // Create the Tooltip
            new Tooltip(element, {
                appendTo: document.body,
                contents: tooltipWrapper,
                positionAppendTo: [Positions.Bottom, Positions.Left],
                modifiers: {
                    size: options.tooltipModifiers.size || TooltipSize.Default,
                    position: options.tooltipModifiers.position || TooltipPosition.TopLeft,
                },
            });
        }
        return element;
    }
}
export default CashbackTag;
