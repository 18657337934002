import { __awaiter, __decorate } from "tslib";
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { isMobile } from '@slickdeals/blueprint-twig/javascript/utilities/experience';
import { isMobileBreakpoint } from '@slickdeals/blueprint-twig/javascript/utilities/breakpoints';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import './condensedCouponHubCard';
// eslint-disable-next-line import/extensions
import { name as PaginationContextServiceName } from '../components/pagination/paginationContextService';
// eslint-disable-next-line import/extensions
import { ClassNames as PaginationClassNames } from '../components/pagination/pagination';
import '../../scss/components/pagination.scss';
import '../../scss/patterns/paginatedGrid.scss';
export const name = 'PaginatedGrid';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-paginatedGrid";
    ClassNames["Javascript"] = "bp-p-paginatedGrid";
    ClassNames["GridItem"] = "bp-p-paginatedGrid_item";
})(ClassNames || (ClassNames = {}));
var Attributes;
(function (Attributes) {
    Attributes["Active"] = "data-active";
    Attributes["Shown"] = "data-show";
})(Attributes || (Attributes = {}));
export const dependencies = Object.assign({}, Injector.createDependency(PaginationContextServiceName, InjectorScope.Factory));
let PaginatedGrid = class PaginatedGrid extends CatalogItem {
    constructor(paginationContextService, options) {
        super(options);
        this.paginationContextService = paginationContextService;
        this.options = options;
        this.gridItems = [];
        this.page = 1;
    }
    /**
     * Peform necessary initialization for the component
     */
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield CatalogItem.loadElement(name, this.options);
            this.isMobileExperience = (yield isMobile()) || isMobileBreakpoint();
            this.numOfPageButtons = this.options.numOfPageButtons.desktop;
            this.resultsPerPage = this.options.itemsPerPage.desktop;
            if (this.isMobileExperience) {
                this.numOfPageButtons = this.options.numOfPageButtons.mobile || this.options.numOfPageButtons.desktop;
                this.resultsPerPage = this.options.itemsPerPage.mobile || this.options.itemsPerPage.desktop;
            }
            this.initializeCards();
            this.toggleItems();
            this.numOfPages = Math.ceil(this.activeItems.length / this.resultsPerPage);
            this.element.appendChild(this.paginationContextService.initContext({
                currentPage: this.page,
                numOfPages: this.numOfPages,
                pagesToShow: this.numOfPageButtons,
                isMobileExperience: this.isMobileExperience,
            }));
            this.initializePaginationControls();
        });
    }
    initializeCards() {
        this.gridItems = Array.from(this.element.querySelectorAll(`.${ClassNames.GridItem}`));
        this.getActiveItems();
        this.getShownItems();
    }
    getActiveItems() {
        this.activeItems = this.gridItems.filter(item => item.hasAttribute(`${Attributes.Active}`));
    }
    getShownItems() {
        this.shownItems = this.gridItems.filter(item => item.hasAttribute(`${Attributes.Shown}`));
    }
    toggleItems() {
        if (this.shownItems.length > 0) {
            this.shownItems.forEach(item => {
                item.toggleAttribute(`${Attributes.Shown}`);
            });
        }
        for (let i = 0; i < this.resultsPerPage; i += 1) {
            const start = (this.page - 1) * this.resultsPerPage;
            if (this.activeItems[start + i] === undefined) {
                break;
            }
            this.activeItems[start + i].toggleAttribute(`${Attributes.Shown}`);
        }
        this.getShownItems();
    }
    initializePaginationControls() {
        // Setup our first, prev, next, and last page buttons
        this.element.querySelector(`.${PaginationClassNames.LeftStart}`).addEventListener('click', this.toFirstPage.bind(this));
        this.element.querySelector(`.${PaginationClassNames.LeftPrev}`).addEventListener('click', this.toPreviousPage.bind(this));
        this.element.querySelector(`.${PaginationClassNames.RightNext}`).addEventListener('click', this.toNextPage.bind(this));
        this.element.querySelector(`.${PaginationClassNames.RightLast}`).addEventListener('click', this.toLastPage.bind(this));
        // Setup our numbered page buttons
        this.element.querySelectorAll(`.${PaginationClassNames.Page}`).forEach(button => {
            const pageButton = button;
            pageButton.addEventListener('click', this.setNumberedPageButton.bind(this));
        });
    }
    toFirstPage() {
        this.page = 1;
        this.toggleItems();
        this.updatePagination();
    }
    toPreviousPage() {
        this.page -= 1;
        this.toggleItems();
        this.updatePagination();
    }
    toNextPage() {
        this.page += 1;
        this.toggleItems();
        this.updatePagination();
    }
    toLastPage() {
        this.page = Math.ceil(this.activeItems.length / this.resultsPerPage);
        this.toggleItems();
        this.updatePagination();
    }
    setNumberedPageButton(event) {
        const button = event.target;
        if (button.dataset.page) {
            this.page = parseInt(button.dataset.page, 10);
            this.toggleItems();
            this.updatePagination();
        }
    }
    updatePagination() {
        this.paginationContextService.refreshPagination({
            currentPage: this.page,
            numOfPages: Math.ceil(this.activeItems.length / this.resultsPerPage),
            pagesToShow: this.numOfPageButtons,
            isMobileExperience: this.isMobileExperience,
        });
        this.initializePaginationControls();
    }
};
PaginatedGrid = __decorate([
    injectable(name, dependencies),
    catalogItem(name)
], PaginatedGrid);
export default PaginatedGrid;
