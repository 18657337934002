import { useAdsCore } from '@slickdeals/ads-core';
import { initEventTracker } from '@slickdeals/web-instrumentation';
export const initPage = () => {
    useAdsCore().initAdStack();
    initEventTracker({
        client: {
            dataLayer: window.dataLayer,
            url: window.location.href,
            device: {
                userAgent: window.navigator.userAgent,
            },
        },
    });
};
