var InlineResponse_1;
import { __awaiter, __decorate } from "tslib";
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { formToJSON } from '../utilities/formSerializer';
import './dealAlertButton';
import '../../scss/patterns/inlineResponse.scss';
/** Name of the component */
const name = 'InlineResponse';
export const ClassNames = {
    Default: 'bp-p-inlineResponse',
    Form: 'bp-p-inlineResponse_form',
    FormHide: 'bp-p-inlineResponse_form--hide',
    Success: 'bp-p-inlineResponse_success',
    Error: 'bp-p-inlineResponse_error',
    ResponseShow: 'bp-p-inlineResponse_response--show',
    ErrorMessage: 'bp-p-inlineResponse_errorMessage',
};
let InlineResponse = InlineResponse_1 = class InlineResponse extends CatalogItem {
    constructor(options) {
        super(options);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield CatalogItem.loadElement(name, this.options);
            this.form = this.element.querySelector(`.${ClassNames.Form}`);
            this.errorElement = this.element.querySelector(`.${ClassNames.Error}`);
            this.errorMessageElement = this.element.querySelector(`.${ClassNames.ErrorMessage}`);
            this.successElement = this.element.querySelector(`.${ClassNames.Success}`);
            this.form.addEventListener('submit', this.submitForm.bind(this));
        });
    }
    submitForm(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event.preventDefault();
            const postData = formToJSON(this.form.elements);
            const action = this.form.getAttribute('action');
            const method = this.form.getAttribute('method');
            let responseData;
            try {
                const response = yield fetch(action, {
                    method,
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: (new URLSearchParams(postData)).toString(),
                });
                responseData = yield response.json();
            }
            catch (e) {
                this.showMessage(this.errorElement);
                return;
            }
            if (responseData.success) {
                this.showMessage(this.successElement);
            }
            else {
                this.showMessage(this.errorElement);
            }
            if (responseData.errors) {
                this.errorMessageElement.innerHTML = responseData.errors.join('<br />');
            }
        });
    }
    showMessage(element) {
        return __awaiter(this, void 0, void 0, function* () {
            this.form.classList.add(ClassNames.FormHide);
            element.classList.add(ClassNames.ResponseShow);
        });
    }
    static createFromDataAttributes(inlineResponseElement) {
        const options = {
            id: inlineResponseElement.id,
            action: inlineResponseElement.dataset.action,
            method: inlineResponseElement.dataset.method,
        };
        return new InlineResponse_1(options);
    }
};
InlineResponse = InlineResponse_1 = __decorate([
    injectable(name),
    catalogItem(name, {
        instantiate: {
            fromDom: true,
            fromMutation: true,
            fromHydrationQueue: false,
        },
    })
], InlineResponse);
export default InlineResponse;
