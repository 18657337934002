var DealAlertButton_1;
import { __awaiter, __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { classNameWithModifier } from '@slickdeals/durango/dist/javascript/utilities/css';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import '@slickdeals/blueprint-twig/javascript/components/button';
import '../../scss/patterns/dealAlertButton.scss';
export const name = 'DealAlertButton';
const CSS_CLASSNAME = 'bp-p-dealAlertButton';
const JS_SELECTOR = 'js-dealAlertButton';
const STATE_READY = 'ready';
const STATE_PENDING = 'pending';
const STATE_SUCCESS = 'success';
let DealAlertButton = DealAlertButton_1 = class DealAlertButton extends BlueprintComponent {
    constructor(options) {
        super(name, options);
        this.state = options.isAdded ? STATE_SUCCESS : STATE_READY;
    }
    static create(options) {
        return new DealAlertButton_1(options);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = (yield BlueprintComponent.loadElement(name, this.options));
            this.element.addEventListener('click', this.onClick.bind(this));
        });
    }
    onClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state !== STATE_READY) {
                return;
            }
            this.setStatePending();
            const postData = Object.assign(Object.assign({ do: 'addByCriteriaId', ajax: '1' }, this.options.dealAlert), { securitytoken: this.options.securityToken });
            let responseData;
            try {
                const response = yield fetch('/forums/sddealalerts_ajax.php', {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: (new URLSearchParams(postData)).toString(),
                });
                responseData = yield response.json();
            }
            catch (e) {
                this.clearState();
                return;
            }
            if (responseData.success) {
                this.setStateSuccess();
            }
            else if (responseData.doLogin) {
                // TODO: Abstract this out
                const regParams = new URLSearchParams({
                    action_source: this.options.dealAlert.source,
                    url: window.location.pathname + window.location.search,
                });
                window.location.href = `/forums/register.php?${regParams}`;
            }
            else {
                this.clearState();
            }
        });
    }
    clearState() {
        this.state = STATE_READY;
        this.element.disabled = false;
        this.element.classList.remove(classNameWithModifier(CSS_CLASSNAME, STATE_PENDING), classNameWithModifier(CSS_CLASSNAME, STATE_SUCCESS));
    }
    setStatePending() {
        this.clearState();
        this.state = STATE_PENDING;
        this.element.disabled = true;
        this.element.classList.add(classNameWithModifier(CSS_CLASSNAME, STATE_PENDING));
    }
    setStateSuccess() {
        this.clearState();
        this.state = STATE_SUCCESS;
        this.element.disabled = true;
        this.element.classList.add(classNameWithModifier(CSS_CLASSNAME, STATE_SUCCESS));
        const contentEl = this.element.getElementsByClassName(classNameWithModifier(JS_SELECTOR, 'content'))[0];
        contentEl.textContent = 'Alert Added';
    }
};
DealAlertButton = DealAlertButton_1 = __decorate([
    injectable(name)
], DealAlertButton);
export default DealAlertButton;
BlueprintComponent.instantiate(name);
