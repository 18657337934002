var VotingThumbsPopup_1;
import { __awaiter, __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { observable } from '@slickdeals/durango/dist/javascript/decorators/observable';
import { stateable } from '@slickdeals/durango/dist/javascript/decorators/stateable';
import { removeAllChildren } from '@slickdeals/durango/dist/javascript/utilities/dom';
import { Positions } from '@slickdeals/durango/dist/javascript/utilities/positioning';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import IconComponent from '@slickdeals/blueprint-twig/javascript/components/icon';
import { TriggerEvents } from '@slickdeals/blueprint-twig/javascript/components/popup';
import ButtonPopup, { ClassNames as ButtonPopupClassNames } from './buttonPopup';
import '../../scss/patterns/votingThumbsPopup.scss';
export const name = 'VotingThumbsPopup';
var VoteTypes;
(function (VoteTypes) {
    VoteTypes["UpVote"] = "upVote";
    VoteTypes["DownVote"] = "downVote";
})(VoteTypes || (VoteTypes = {}));
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-votingThumbsPopup";
    ClassNames["UpVote"] = "bp-p-votingThumbsPopup_upVote";
    ClassNames["DownVote"] = "bp-p-votingThumbsPopup_downVote";
    ClassNames["JavaScript"] = "js-votingThumbsPopup";
    ClassNames["Icon"] = "js-votingThumbsPopup_icon";
    ClassNames["VoteCount"] = "js-votingThumbsPopup_voteCount";
    ClassNames["DarkMode"] = "bp-s-darkMode";
})(ClassNames || (ClassNames = {}));
let VotingThumbsPopup = VotingThumbsPopup_1 = class VotingThumbsPopup extends BlueprintComponent {
    constructor(options) {
        super(name, options);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield BlueprintComponent.loadElement(name, this.options);
            // Initalizes the state
            const initState = {
                voteCount: parseInt(this.options.voteCount, 10),
                voteType: this.options.voteType,
            };
            // Initalize the module's state
            this.initState(initState);
            this.initButtonPopup();
            this.voteCountElement = this.element.querySelector(`.${ClassNames.VoteCount}`);
            const userHasVotedAttribute = this.element.dataset.votingType;
            if (userHasVotedAttribute) {
                this.handleVoteType(userHasVotedAttribute);
            }
            /**
             * Comes from slickdeals/clientscripts/vbulletin_sdthreadrate.js
             * Dispatches a userVote event if a user has voted on a thread
             * Information dispatched will either be "up" or "down"
             */
            this.element.addEventListener('userVoted', this.handleUserVoted.bind(this));
        });
    }
    /**
     * Handles the display of the initial (on page load) users vote
     * @param {UserVotedEvent} event Event fired from vbulletin_sdthreadrate.js
     */
    handleUserVoted(event) {
        this.handleVoteType(event.detail.voteType);
    }
    handleVoteType(voteType) {
        let newVoteType;
        if (voteType === 'up') {
            newVoteType = VoteTypes.UpVote;
        }
        if (voteType === 'down') {
            newVoteType = VoteTypes.DownVote;
        }
        if (!newVoteType) {
            return;
        }
        this.setState({ voteType: newVoteType });
        this.updateIcon();
        this.updateVoteCountStyles();
    }
    /**
     * Create a VotingThumbsPopup instance from an already-present DOM element using data attributes
     *
     * @param popupElement The HTML element that triggers the voting thumbs to appear
     */
    static createFromDataAttributes(popupElement) {
        const options = {
            id: popupElement.id,
            buttonPopupId: `${popupElement.dataset.votingthumbspopupButtonpopupid}-Popup`,
            voteCount: Number(popupElement.dataset.votingthumbspopupVotecount),
            postId: Number(popupElement.dataset.votingthumbspopupPostid),
            actionSource: `${popupElement.dataset.votingthumbspopupActionsource}`,
            securityToken: popupElement.dataset.votingthumbspopupSecuritytoken,
        };
        // Create and return the Tooltip instance
        return new VotingThumbsPopup_1(options);
    }
    /**
     * Create the buttonPopup instance for this module
     */
    initButtonPopup() {
        const button = document.getElementById(this.options.buttonPopupId);
        button
            .classList
            .add(ButtonPopupClassNames.VotingThumbs);
        // TODO: Remove once darkMode doesn't have to be detected on a per-module basis
        if (this.element.classList.contains(ClassNames.DarkMode)) {
            button
                .classList
                .add(ClassNames.DarkMode);
        }
        this.buttonPopup = new ButtonPopup({
            id: this.options.buttonPopupId,
            triggerId: this.element.id,
            position: [Positions.Top, Positions.Left],
            triggerEvent: TriggerEvents.Hover,
        });
        // Sets an observable which is triggered whenever a buttonPopup item is clicked on
        // Once clicked it, it will set the triggerContent to which item was selected
        this.buttonPopup.subscribe(({ buttonIdentifier }) => this.doVote(buttonIdentifier));
    }
    /**
     * Sets the correct icon on the trigger and handles the vote count
     * @param {VoteTypes} voteType - The type of vote in which to set the trigger icon content to
     */
    doVote(voteType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (voteType !== this.getState().voteType) {
                try {
                    // Send voteType rather than reading off of state so if sendVote fails
                    // state is still correct
                    yield this.sendVote(voteType);
                    // Resets the voting count - not reflected on DOM
                    this.resetVoteCount();
                    this.setState({ voteType });
                    this.updateIcon();
                    this.updateVoteCount();
                }
                catch (error) {
                    // Do nothing
                }
            }
        });
    }
    /**
     * Sets the icon on the trigger based on the current vote type
     */
    updateIcon() {
        const icon = this.element.querySelector(`.${ClassNames.Icon}`);
        let thumbIcon;
        if (this.getState().voteType === VoteTypes.UpVote) {
            thumbIcon = IconComponent.createElement({ name: 'thumbUp', classNames: [ClassNames.UpVote] });
        }
        else if (this.getState().voteType === VoteTypes.DownVote) {
            thumbIcon = IconComponent.createElement({ name: 'thumbDown', classNames: [ClassNames.DownVote] });
        }
        else {
            return;
        }
        // Removes the icon content
        removeAllChildren(icon);
        // Adds the new icon
        icon.appendChild(thumbIcon);
    }
    /**
     * Sets the vote count based on the current vote type
     */
    updateVoteCount() {
        let { voteCount } = this.getState();
        if (this.getState().voteType === VoteTypes.UpVote) {
            voteCount += 1;
        }
        else if (this.getState().voteType === VoteTypes.DownVote) {
            voteCount -= 1;
        }
        else {
            return;
        }
        this.updateVoteCountStyles();
        this.setState({ voteCount });
        // Adds the new vote count back to its container
        this.voteCountElement.textContent = (this.getState().voteCount).toString();
    }
    /**
     * Handles the coloring of the voteCount container
     */
    updateVoteCountStyles() {
        if (this.getState().voteType === VoteTypes.UpVote) {
            if (this.voteCountElement.classList.contains(ClassNames.DownVote)) {
                this.voteCountElement.classList.remove(ClassNames.DownVote);
            }
            this.voteCountElement.classList.add(ClassNames.UpVote);
            return;
        }
        if (this.voteCountElement.classList.contains(ClassNames.UpVote)) {
            this.voteCountElement.classList.remove(ClassNames.UpVote);
        }
        this.voteCountElement.classList.add(ClassNames.DownVote);
    }
    /**
     * Sets this.voteCount to the vote count to its original state (not yet voted on)
     */
    resetVoteCount() {
        let { voteCount } = this.getState();
        // Reset vote count based on current active vote type
        if (this.getState().voteType === VoteTypes.UpVote) {
            voteCount -= 1;
        }
        else if (this.getState().voteType === VoteTypes.DownVote) {
            voteCount += 1;
        }
        else {
            // Current vote count is correct
            return;
        }
        this.setState({ voteCount });
    }
    /**
     * Sends the vote to the backend to be registered
     *
     * @param {VoteTypes} voteType Type of vote
     */
    sendVote(voteType) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const vote = voteType === VoteTypes.UpVote ? '1' : '-1';
            const voteTypeId = voteType === VoteTypes.UpVote ? '1' : '0';
            const data = new URLSearchParams({
                ajax: '1',
                controltype: 'modern',
                postid: this.options.postId.toString(),
                vote,
                votetypeid: voteTypeId,
                securitytoken: this.options.securityToken,
                where_from: '/forums/sdthreadrate_ajax.php',
                do: 'sdthreadratevote',
                action_source: this.options.actionSource,
            });
            const response = yield fetch('/forums/sdthreadrate_ajax.php', {
                method: 'POST',
                body: data,
            });
            // Response will be XML, only text will strip it out of the body properly
            const responseText = yield response.text();
            // Transform XML into a DocumentFragment
            const domParser = new DOMParser();
            const dom = domParser.parseFromString(responseText, 'application/xml');
            // Check if the user is not logged in
            // XML will send back a <dologin> tag if they're not logged in
            const isNotLoggedIn = dom.querySelector('dologin');
            // If they aren't logged in, redirect them to the register page
            if (isNotLoggedIn) {
                const params = new URLSearchParams();
                params.set('action_source', (_a = this.options.actionSource) !== null && _a !== void 0 ? _a : 'Thread Vote');
                window.location.href = '/forums/register.php?' + params.toString();
            }
            // Check if there was an error in XML
            // There will either be an <error> tag or a voteError attribute sent back
            const isError = dom.querySelector('error') || dom.querySelector('[voteError="1"]');
            // If there is an error, throw new error to break out of await
            if (response.status !== 200 || isError || isNotLoggedIn) {
                throw new Error('Vote submission error');
            }
        });
    }
};
VotingThumbsPopup = VotingThumbsPopup_1 = __decorate([
    observable,
    stateable(name),
    injectable(name)
], VotingThumbsPopup);
export default VotingThumbsPopup;
BlueprintComponent.instantiate(name, {
    fromDom: true,
    fromMutation: true,
    fromHydrationQueue: false,
});
