import JsxComponent from '@slickdeals/blueprint-twig/javascript/core/jsxComponent';
export const name = 'CashbackInterstitialFailure';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-cashbackInterstitialFailure";
    ClassNames["Javascript"] = "js-cashbackInterstitialFailure";
    ClassNames["ReturnButton"] = "bp-p-interstitialFailure_button";
    ClassNames["ProceedLink"] = "bp-p-interstitialFailure_proceedLink";
})(ClassNames || (ClassNames = {}));
export var DataRoleNames;
(function (DataRoleNames) {
    DataRoleNames["CloseButton"] = "returnToDealDetailsFromInterstitial";
    DataRoleNames["ProceedButton"] = "proceedLinkFromInterstitial";
})(DataRoleNames || (DataRoleNames = {}));
class CashbackInterstitialFailure extends JsxComponent {
    constructor() {
        super(name);
    }
    /**
     * Creates the inner parts of the failure interstitial provided the data
     *
     * @param props Data used to render element
     * @returns HTMLDivElement The inner components for failure interstitial
     */
    createElement(props) {
        const { merchantStoreLogo, merchantLink, cashbackRate } = props;
        const link = merchantLink.replace(/&amp;/g, '&');
        this.interstitialContainerStylesOverride();
        return (JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure js-interstitialFailure" },
            JsxComponent.parseJsx("img", { src: "/image-pool/sd-branding/sd-logo-full-blueberry.svg", className: "bp-p-interstitialFailure_logo" }),
            JsxComponent.parseJsx("img", { src: merchantStoreLogo, alt: "Store logo", className: "bp-p-interstitialFailure_storeLogo" }),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_subhead" }, "Oh no! Adblock Detected."),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_headline" },
                cashbackRate,
                " Cashback Blocked"),
            JsxComponent.parseJsx("img", { className: "bp-p-interstitialFailure_img", src: "/image-pool/loyalty/no-extension/AlertIcon.svg", alt: "" }),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_info" },
                JsxComponent.parseJsx("p", null, "Pause your Adblock Extension to get your Deal and Cashback"),
                JsxComponent.parseJsx("button", { className: ClassNames.ReturnButton }, "Return to Deal Thread"),
                JsxComponent.parseJsx("button", { className: ClassNames.ProceedLink, "data-href": link }, "Proceed without Cashback"))));
    }
    /**
     * Creates a style element used to override generic modal container styles. This is a temp solution and will need to be re-worked in a follow up ticket.
     */
    interstitialContainerStylesOverride() {
        const styles = document.createElement('style');
        styles.id = 'styleBlockOverride';
        const content = `
      .bp-c-popup {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        max-height: 100vh !important;
        border-radius: 0 !important;
        transform: translate(0, 0) !important;
      }
    `;
        styles.innerHTML = content;
        document.body.append(styles);
    }
}
export default CashbackInterstitialFailure;
