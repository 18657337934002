var _a;
import { __awaiter } from "tslib";
export default class AdBlockerDetectionService {
    static isAffiliateRequestsBlocked() {
        // TODO: Extend this list to more URLs to check for. These are the 2 that both Adblock and Ublock use.
        const urls = ['https://go.redirectingat.com', 'https://www.pntrs.com'];
        return AdBlockerDetectionService.areRequestsBlocked(urls);
    }
}
_a = AdBlockerDetectionService;
/** Detects whether adblocker is enabled
* @param callback
*/
AdBlockerDetectionService.areRequestsBlocked = (urls) => __awaiter(void 0, void 0, void 0, function* () {
    let blocked = false;
    for (let i = 0; i < urls.length; i += 1) {
        if (blocked) {
            break;
        }
        const url = urls[i];
        try {
            // eslint-disable-next-line no-await-in-loop
            yield fetch(url, {
                method: 'HEAD',
                mode: 'no-cors',
            });
            blocked = false;
        }
        catch (e) {
            blocked = true;
        }
    }
    return blocked;
});
