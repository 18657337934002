var ButtonLoader_1;
import { __awaiter, __decorate } from "tslib";
import { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
export const name = 'ButtonLoader';
const svgHeightPx = 109;
const svgWidthPx = 300;
var ClassNames;
(function (ClassNames) {
    ClassNames["LoadingImage"] = "js-buttonLoaderImage";
})(ClassNames || (ClassNames = {}));
const rgba2hex = (rgba) => {
    const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/);
    const rgbaValues = match.slice(1);
    const hexValues = rgbaValues.map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
        .toString(16)
        .padStart(2, '0')
        .replace('NaN', ''));
    return `#${hexValues.join('')}`;
};
// Preload SVG so that first button transition is instant
const svgHtmlPromise = (() => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield fetch('https://static.slickdealscdn.com/image-pool/icons/loadingDots.svg');
        return yield resp.text();
    }
    catch (e) {
        Logger.error('ButtonLoader preload failed', e);
        return null;
    }
}))();
let ButtonLoader = ButtonLoader_1 = class ButtonLoader {
    static showLoader(button) {
        return __awaiter(this, void 0, void 0, function* () {
            /* eslint-disable no-param-reassign */
            button.disabled = true;
            const svgHtml = yield svgHtmlPromise;
            if (!svgHtml) {
                Logger.error('ButtonLoader error: no loading image');
                return;
            }
            try {
                const computedStyle = window.getComputedStyle(button);
                // Prep button to allow absolutely positioned loading image
                const { position } = computedStyle;
                if (position === 'absolute') {
                    Logger.error('ButtonLoader error: absolute positioned buttons not supported');
                    return;
                }
                if (position !== 'fixed' && position !== 'sticky') {
                    button.style.position = 'relative';
                }
                // Change the text nodes in the Button to be transparent,
                // and get the original font color to use to color the SVG.
                // This ensures the button maintains it's size.
                let fillColor = computedStyle.color;
                if (fillColor.startsWith('rgb')) {
                    fillColor = rgba2hex(fillColor);
                }
                if (!fillColor || !fillColor.startsWith('#')) {
                    Logger.error(`ButtonLoader error: invalid fillColor: ${fillColor}`);
                    return;
                }
                button.style.color = 'transparent';
                const loadingImage = ButtonLoader_1.createLoadingImage(svgHtml, computedStyle.fontSize, fillColor);
                button.appendChild(loadingImage);
            }
            catch (e) {
                Logger.debug('ButtonLoader failed to show loading state', e);
            }
        });
    }
    static createLoadingImage(svgHtml, heightStyle, hexColor) {
        const widthStyle = `${Math.round(parseInt(heightStyle, 10) * svgWidthPx / svgHeightPx)}px`;
        const loadingImage = document.createElement('object');
        loadingImage.classList.add(ClassNames.LoadingImage);
        loadingImage.style.height = heightStyle;
        loadingImage.style.width = widthStyle;
        loadingImage.style.position = 'absolute';
        loadingImage.style.left = '50%';
        loadingImage.style.top = '50%';
        loadingImage.style.transform = 'translate(-50%,-50%)';
        // This is a cross origin request, so we need to inject the SVG HTML ourselves
        // in order to be able to manipulate it (i.e. change the fill color)
        loadingImage.innerHTML = svgHtml;
        // Setting the fill is currently coupled to DOM of the specific SVG being used.
        loadingImage.querySelectorAll('circle')
            .forEach(circle => circle.setAttribute('fill', hexColor));
        return loadingImage;
    }
    // eslint-disable-next-line class-methods-use-this
    static showContents(button) {
        /* eslint-disable no-param-reassign */
        // Remove the loading image
        const loadingImage = button.querySelector(`.${ClassNames.LoadingImage}`);
        if (loadingImage) {
            loadingImage.remove();
        }
        if (button.getAttribute('style')) {
            // Revert any style changes (font color, position)
            button.setAttribute('style', '');
        }
        button.disabled = false;
    }
};
ButtonLoader = ButtonLoader_1 = __decorate([
    injectable({ key: name, scope: InjectorScope.Singleton })
], ButtonLoader);
export default ButtonLoader;
