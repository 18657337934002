import '@slickdeals/blueprint-twig/javascript/components/lazyImage';
import '@slickdeals/blueprint-twig/javascript/components/link';
import '../../scss/components/card.scss';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-c-card";
    ClassNames["JavaScript"] = "js-card";
    ClassNames["Header"] = "bp-c-card_header";
    ClassNames["JavaScriptHeader"] = "js-card_header";
})(ClassNames || (ClassNames = {}));
