import LazyLoad from '@slickdeals/blueprint-twig/javascript/core/lazyLoad';
import '@slickdeals/blueprint-twig/javascript/patterns/breadcrumbs';
import '@slickdeals/blueprint-twig/javascript/components/unorderedList';
import '@slickdeals/blueprint-twig/javascript/components/button';
import '@slickdeals/blueprint-twig/javascript/components/icon';
import '@slickdeals/blueprint-twig/javascript/components/figure';
import '@slickdeals/blueprint-twig/javascript/components/panel';
import '@slickdeals/blueprint-twig/javascript/components/listPicker';
import '../patterns/adBlock';
import '../patterns/blueberryAnythingCard';
import '../patterns/blueberryDealAlertCard';
import '../patterns/blueberryDealCard';
import '../patterns/dealAlertButton';
import '../patterns/paginatedGrid';
import '../patterns/filterGrid';
import '../patterns/readMoreButton';
import '../patterns/facetedFilters';
import '../patterns/reasonToBelieveModule';
import '../patterns/dealSortListPicker';
import '../patterns/categoryIconCarousel';
import '../patterns/popularRelatedArticlesCarousel';
import '../patterns/emptyCategory';
import '../patterns/inlineResponse';
import '../layouts/blueberry';
import '../../scss/pages/categoryPage.scss';
import { initPage } from '../utilities/initPage';
new LazyLoad(); // eslint-disable-line no-new
initPage();
