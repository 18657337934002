var FilterGrid_1;
import { __awaiter, __decorate } from "tslib";
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import './condensedCouponHubCard';
// eslint-disable-next-line import/extensions
import { name as PaginationContextServiceName } from '../components/pagination/paginationContextService';
// eslint-disable-next-line import/extensions
import { ClassNames as PaginationClassNames } from '../components/pagination/pagination';
import '../../scss/components/pagination.scss';
import '../../scss/patterns/filterGrid.scss';
const name = 'FilterGrid';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-filterGrid";
    ClassNames["Javascript"] = "bp-p-filterGrid";
    ClassNames["GridItem"] = "bp-p-filterGrid";
})(ClassNames || (ClassNames = {}));
export const dependencies = Object.assign({}, Injector.createDependency(PaginationContextServiceName, InjectorScope.Factory));
let FilterGrid = FilterGrid_1 = class FilterGrid extends CatalogItem {
    constructor(paginationContextService, options) {
        super(options);
        this.paginationContextService = paginationContextService;
        this.options = options;
    }
    /**
     * Peform necessary initialization for the component
     */
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield CatalogItem.loadElement(name, this.options);
            this.initializePaginationControls();
        });
    }
    initializePaginationControls() {
        this.element.appendChild(this.paginationContextService.initContext({
            currentPage: this.options.currentPage,
            numOfPages: this.options.maxPages,
            pagesToShow: 3,
            isMobileExperience: false,
        }));
        // Setup our first, prev, next, and last page buttons
        this.element.querySelector(`.${PaginationClassNames.LeftStart}`).addEventListener('click', this.toFirstPage.bind(this));
        this.element.querySelector(`.${PaginationClassNames.LeftPrev}`).addEventListener('click', this.toPreviousPage.bind(this));
        this.element.querySelector(`.${PaginationClassNames.RightNext}`).addEventListener('click', this.toNextPage.bind(this));
        this.element.querySelector(`.${PaginationClassNames.RightLast}`).addEventListener('click', this.toLastPage.bind(this));
        // Setup our numbered page buttons
        this.element.querySelectorAll(`.${PaginationClassNames.Page}`).forEach(button => {
            const pageButton = button;
            pageButton.addEventListener('click', FilterGrid_1.handlePageNumberClick.bind(this));
        });
    }
    static handlePageNumberClick(e) {
        const button = e.target;
        const url = new URL(window.location.toString());
        url.searchParams.set('page', button.dataset.page);
        window.location.assign(url.toString());
    }
    toFirstPage() {
        const url = new URL(window.location.toString());
        url.searchParams.set('page', this.options.minPages.toString());
        window.location.assign(url.toString());
    }
    toPreviousPage() {
        const url = new URL(window.location.toString());
        url.searchParams.set('page', (this.options.currentPage - 1).toString());
        window.location.assign(url.toString());
    }
    toNextPage() {
        const url = new URL(window.location.toString());
        url.searchParams.set('page', (this.options.currentPage + 1).toString());
        window.location.assign(url.toString());
    }
    toLastPage() {
        const url = new URL(window.location.toString());
        url.searchParams.set('page', this.options.maxPages.toString());
        window.location.assign(url.toString());
    }
};
FilterGrid = FilterGrid_1 = __decorate([
    injectable(name, dependencies),
    catalogItem(name)
], FilterGrid);
export default FilterGrid;
