import { __awaiter, __decorate } from "tslib";
import { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
export const name = 'Heap';
let Heap = class Heap {
    /**
       * Call Heap's track method.
       * See https://developers.heap.io/reference#track
       *
       * @param eventName
       * @param eventProperties
       */
    track(eventName, eventProperties) {
        return this.wrap(heapApi => heapApi.track(eventName, eventProperties));
    }
    /**
       * Takes care of retrieving Heap instance, error handling, and promisifying.
       *
       * @param heapCall a function call to the external Heap API
       * @private
       */
    // eslint-disable-next-line class-methods-use-this
    wrap(heapCall) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // window.heap will be the API or an Array queue
                const heapApi = window.heap;
                return heapCall(heapApi);
            }
            catch (err) {
                Logger.error(`Heap error: ${err.message}`);
                throw err;
            }
        });
    }
};
Heap = __decorate([
    injectable({ key: name, scope: InjectorScope.Singleton })
], Heap);
export default Heap;
