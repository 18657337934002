import { __awaiter, __decorate } from "tslib";
import DOMPurify from 'dompurify';
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { TooltipSize, TooltipPosition } from '@slickdeals/blueprint-twig/javascript/components/tooltip';
import CashbackTag from './cashbackTag';
import '../components/card';
import '@slickdeals/blueprint-twig/javascript/components/ratingStars';
import '../../scss/patterns/condensedCouponHubCard.scss';
/** The name of the module */
export const name = 'CondensedCouponHubCard';
export var ClassNames;
(function (ClassNames) {
    ClassNames["JavaScript"] = "js-condensedCouponHubCard";
    ClassNames["Default"] = "bp-c-condensedCouponHubCard";
    ClassNames["CashbackTagContainer"] = "bp-c-card_subtitle";
})(ClassNames || (ClassNames = {}));
let CondensedCouponHubCard = class CondensedCouponHubCard extends CatalogItem {
    /**
     * Create a PopularProducts UI component
     * @param options Data and options to initialize the component with
     */
    constructor(options) {
        super(options);
        this.options = options;
    }
    /**
     * Perform any necessary initialization for the component
     */
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield CatalogItem.loadElement(name, this.options);
            if (this.options.rate) {
                this.initializeCashbackTag({
                    rate: this.options.rate,
                    tooltipContent: this.options.tooltipContent,
                });
            }
        });
    }
    /**
     * Initializes cashbackTag component with provided options
     * @param options Options to initialize cashbackTag with
     */
    initializeCashbackTag({ rate, tooltipContent }) {
        const cashbackTagContainer = this.element.querySelector(`.${ClassNames.CashbackTagContainer}`);
        const tag = CashbackTag.createElement(rate, {
            modifiers: [],
            tooltip: tooltipContent ? DOMPurify.sanitize(tooltipContent, { RETURN_DOM_FRAGMENT: true }) : null,
            tooltipModifiers: {
                size: TooltipSize.Default,
                position: TooltipPosition.BottomLeft,
            },
        });
        // Overriding default component styles
        tag.style.display = 'inline-flex';
        cashbackTagContainer.appendChild(tag);
    }
};
CondensedCouponHubCard = __decorate([
    injectable(name),
    catalogItem(name)
], CondensedCouponHubCard);
export default CondensedCouponHubCard;
