const isValidElement = (element) => element.name && element.value;
const isValidValue = (element) => (!['checkbox', 'radio'].includes(element.type) || element.checked);
const isCheckbox = (element) => element.type === 'checkbox';
const isMultiSelect = (element) => element.options && element.multiple;
const getSelectValues = (options) => [].reduce.call(options, (values, option) => (option.selected ? values.concat(option.value) : values), []);
export const formToJSON = elements => [].reduce.call(elements, (data, formElement) => {
    const newData = Object.assign({}, data);
    if (isValidElement(formElement) && isValidValue(formElement)) {
        if (isCheckbox(formElement)) {
            newData[formElement.name] = (data[formElement.name] || []).concat(formElement.value);
        }
        else if (isMultiSelect(formElement)) {
            newData[formElement.name] = getSelectValues(formElement);
        }
        else {
            newData[formElement.name] = formElement.value;
        }
    }
    return newData;
}, {});
