import { __awaiter, __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { observable } from '@slickdeals/durango/dist/javascript/decorators/observable';
import { classNameWithModifier } from '@slickdeals/durango/dist/javascript/utilities/css';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import Popup, { AnchorTypes } from '@slickdeals/blueprint-twig/javascript/components/popup';
import { Animations } from '@slickdeals/blueprint-twig/javascript/utilities/animation';
import '../../scss/patterns/buttonPopup.scss';
export const name = 'ButtonPopup';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-buttonPopup";
    ClassNames["JavaScript"] = "js-buttonPopup";
    ClassNames["VotingThumbs"] = "bp-p-buttonPopup--votingThumbs";
})(ClassNames || (ClassNames = {}));
let ButtonPopup = class ButtonPopup extends BlueprintComponent {
    constructor(options) {
        super(name, options);
        this.addAnimation = ({ target }) => target.classList.add(classNameWithModifier(Animations.Grow, 'active'));
        this.removeAnimation = ({ target }) => target.classList.remove(classNameWithModifier(Animations.Grow, 'active'));
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield BlueprintComponent.loadElement(name, this.options);
            this.initPopup();
            const buttons = this.element.querySelectorAll('button');
            buttons.forEach(button => {
                button.classList.add(Animations.Grow);
                button.addEventListener('click', this.handleClick.bind(this));
                button.addEventListener('mouseenter', this.addAnimation);
                button.addEventListener('mouseleave', this.removeAnimation);
            });
        });
    }
    /**
     * Creates the popup instance with all options
     */
    initPopup() {
        this.popup = new Popup({
            id: this.options.id,
            position: this.options.position,
            triggerId: this.options.triggerId,
            triggerEvent: this.options.triggerEvent,
            anchorType: AnchorTypes.Trigger,
            // Baking in as default behavior to simplify the positioning
            changeParentToBody: true,
        });
    }
    /**
     * Dispatches the button click and hides the popup
     */
    handleClick({ target, currentTarget }) {
        this.popup.hide();
        let buttonTarget;
        if (currentTarget && currentTarget instanceof HTMLButtonElement) {
            buttonTarget = currentTarget;
        }
        else if (target.parentElement instanceof HTMLButtonElement) {
            // If currentTarget isn't the button, search its parent
            // If its parent isn't a button, then the button contains invalid HTML
            // or the child elements don't contain button elements
            buttonTarget = target.parentElement;
        }
        else {
            Logger.error('Cannot find button element of buttonPopup child - target');
            return;
        }
        // The identifier which will tell TS what item was clicked on within the buttonPopup
        const buttonIdentifier = buttonTarget.dataset.targetIdentifier;
        if (!buttonIdentifier) {
            Logger.error('A Button Popup Item must have a \'target-identifier\' data attribute set on it');
        }
        this.notify({ buttonIdentifier });
    }
};
ButtonPopup = __decorate([
    observable,
    injectable(name)
], ButtonPopup);
export default ButtonPopup;
BlueprintComponent.instantiate(name);
