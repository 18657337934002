import { __awaiter, __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import Redirector from '@slickdeals/durango/dist/javascript/utilities/redirector';
import { mediatable } from '@slickdeals/blueprint-twig/javascript/decorators/mediatable';
import '../../scss/patterns/dealSortListPicker.scss';
export const name = 'DealSortListPicker';
/** Classnames for this module */
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-DealSortListPicker";
    ClassNames["JavaScript"] = "js-DealSortListPicker";
    ClassNames["ListPicker"] = "js-listPicker";
})(ClassNames || (ClassNames = {}));
/** Mediated events triggered by this module */
export var MediatedEvents;
(function (MediatedEvents) {
    MediatedEvents["Change"] = "change";
    MediatedEvents["Input"] = "input";
})(MediatedEvents || (MediatedEvents = {}));
let DealSortListPicker = class DealSortListPicker extends CatalogItem {
    /**
     * @param options Data and options to initialize the component with
     */
    constructor(options) {
        super(options);
        this.options = options;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = (yield CatalogItem.loadElement(name, this.options));
            this.listPicker = document.querySelector(`.${ClassNames.ListPicker}`); // can't find component inside element
            this.listPicker.addEventListener(`${MediatedEvents.Change}`, () => {
                const selectedOption = this.listPicker.selectedOptions[0];
                const selectedSort = selectedOption.value;
                const redirectUrl = new URL(window.location.href);
                redirectUrl.searchParams.set('sort', selectedSort);
                Redirector.redirect(redirectUrl.toString());
            });
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const paramValue = urlParams.get('sort');
            this.listPicker.querySelector(`option[value='${paramValue}']`).setAttribute('selected', 'selected');
        });
    }
};
DealSortListPicker = __decorate([
    catalogItem(name),
    injectable(name),
    mediatable(name, Object.values(MediatedEvents))
], DealSortListPicker);
export default DealSortListPicker;
