import JsxComponent from '@slickdeals/blueprint-twig/javascript/core/jsxComponent';
export const name = 'CashbackInterstitialSuccess';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-interstitialSuccess";
    ClassNames["Javascript"] = "js-interstitialSuccess";
    ClassNames["Checkbox"] = "bp-p-interstitialSuccess_checkbox";
    ClassNames["CountdownValue"] = "bp-p-interstitialSuccess_infoCountdownValue";
})(ClassNames || (ClassNames = {}));
class RFAInterstitialSuccess extends JsxComponent {
    constructor() {
        super(name);
    }
    /**
     * Creates the inner parts of the success interstitial provided the data
     *
     * @param props Data used to render element
     * @returns HTMLDivElement The inner components for success interstitial
     */
    createElement(props) {
        const { outclickStoreId, outclickStoreSlug } = props;
        const storeLogo = `/images/slickdeals/storelogo/128x128/${outclickStoreId}.png`;
        const storeTermsLink = `/rewards/${outclickStoreSlug}/terms/`;
        // Sets style overrides to be used for the success interstitial
        this.interstitialContainerStylesOverride();
        const interstitialSuccessClassNames = [ClassNames.Default, ClassNames.Javascript].join(' ');
        return (JsxComponent.parseJsx("div", { className: interstitialSuccessClassNames },
            JsxComponent.parseJsx("img", { src: "/image-pool/sd-branding/sd-logo-full-blueberry.svg", className: "bp-p-interstitialSuccess_logo" }),
            JsxComponent.parseJsx("img", { src: storeLogo, alt: "Store logo", className: "bp-p-interstitialSuccess_storeLogo" }),
            JsxComponent.parseJsx("img", { className: "bp-p-interstitialSuccess_slicky", src: "https://slickdeals.net/lp/wp-content/uploads/rewards/slicky-cashback-activating.svg", alt: "Slicky Cashback Activating" }),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialSuccess_mainInfo" },
                JsxComponent.parseJsx("div", { className: "bp-p-interstitialSuccess_heading" }, "Cashback Activated"),
                JsxComponent.parseJsx("div", { className: "bp-p-interstitialSuccess_subheading" }, "Congratulations! You're about to get paid to shop."),
                JsxComponent.parseJsx("a", { className: "bp-p-interstitialSuccess_terms", href: storeTermsLink, "data-role": "cashbackInterstitialStoreTerms", target: "_blank" }, "SEE TERMS AND CONDITIONS"),
                JsxComponent.parseJsx("p", { className: "bp-p-interstitialSuccess_countdown" },
                    "Redirecting in ",
                    JsxComponent.parseJsx("span", { className: ClassNames.CountdownValue }, "3"),
                    " seconds...")),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialSuccess_footer" },
                JsxComponent.parseJsx("input", { className: "bp-p-interstitialSuccess_checkbox", "data-role": "disableCashbackInterstitialCheckbox", type: "checkbox" }),
                JsxComponent.parseJsx("p", { className: "bp-p-interstitialSuccess_checkboxMessage" }, "Ok, got it! No need to notify me for now."))));
    }
    /**
     * Creates a style element used to override generic modal container styles. This is a temp solution and will need to be re-worked in a follow up ticket.
     */
    interstitialContainerStylesOverride() {
        const styles = document.createElement('style');
        styles.id = 'styleBlockOverride';
        const content = `
      .bp-c-popup {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        max-height: 100vh !important;
        border-radius: 0 !important;
        transform: translate(0, 0) !important;
      }
    `;
        styles.innerHTML = content;
        document.body.append(styles);
    }
}
export default RFAInterstitialSuccess;
