var CashbackTagActivationModal_1;
import { __awaiter, __decorate } from "tslib";
import CatalogItem from '@slickdeals/durango/dist/javascript/core/catalogItem';
import { catalogItem } from '@slickdeals/durango/dist/javascript/decorators/catalogItem';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import CookieService, { name as cookieServiceName } from '@slickdeals/blueprint-twig/javascript/cookies/cookieService';
import Redirector from '@slickdeals/durango/dist/javascript/utilities/redirector';
import Modal, { ClassNames as ModalClassNames } from '@slickdeals/blueprint-twig/javascript/components/modal';
import { name as jobSubscriberName, QOSLevels, } from '@slickdeals/blueprint-twig/javascript/queues/jobQueue/jobSubscriber';
import { joinLoyalty, loyaltyJoinTopic } from '../utilities/loyalty';
import ButtonLoader from '../utilities/buttonLoader';
import Heap, { name as heapName } from '../heap/heap';
import '@slickdeals/blueprint-twig/javascript/components/linkableButton';
import '../../scss/patterns/cashbackTagActivationModal.scss';
export const name = 'CashbackTagActivationModal';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-cashbackTagActivationModal";
    ClassNames["HeaderText"] = "bp-p-cashbackTagActivationModal_headerText";
    ClassNames["BodyText"] = "bp-p-cashbackTagActivationModal_bodyText";
    ClassNames["CtaButton"] = "bp-p-cashbackTagActivationModal_ctaButton";
    ClassNames["LearnMoreLink"] = "bp-p-cashbackTagActivationModal_learnMoreLink";
    ClassNames["CloseButton"] = "bp-p-cashbackTagActivationModal_closeButton";
    ClassNames["RemindMeLaterButton"] = "bp-p-cashbackTagActivationModal_remindMeLaterButton";
    ClassNames["ContainerOverride"] = "bp-p-cashbackTagActivationModal_containerOverride";
    ClassNames["HeroImage"] = "bp-p-cashbackTagActivationModal_heroImage";
    ClassNames["ActivatedImage"] = "bp-p-cashbackTagActivationModal_activatedImage";
})(ClassNames || (ClassNames = {}));
export const dependencies = Object.assign(Object.assign(Object.assign({}, Injector.createDependency(cookieServiceName, InjectorScope.Singleton)), Injector.createDependency(heapName, InjectorScope.Singleton)), Injector.createDependency(jobSubscriberName, InjectorScope.Singleton));
const activationSource = 'CashbackTagActivationModal';
const activatedImgSrc = '/image-pool/loyalty/rewards-for-all/slickySuccessStars.svg';
const activatedHeaderText = 'Hooray! Cashback Activated.';
const activatedBodyText = 'Shop like usual. Rake in your Points, redeemable for PayPal credit and more. Keep an eye out for an email with more details coming soon.';
const activatedButtonText = 'Keep Shopping';
let CashbackTagActivationModal = CashbackTagActivationModal_1 = class CashbackTagActivationModal extends CatalogItem {
    constructor(cookieService, heap, subscriber, options) {
        super(options);
        this.cookieService = cookieService;
        this.heap = heap;
        this.subscriber = subscriber;
        this.options = options;
        this.isActivated = false;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield CatalogItem.loadElement(name, this.options);
            this.modal = new Modal({
                content: this.element,
                closeButton: false,
                isFixed: true,
            });
            this.ctaButton = this.element.querySelector(`.${ClassNames.CtaButton}`);
            this.ctaButton.addEventListener('click', this.onCtaClick.bind(this));
            const closeButton = this.element.querySelector(`.${ClassNames.CloseButton}`);
            closeButton.addEventListener('click', () => this.hide());
            const remindMeLaterButton = this.element.querySelector(`.${ClassNames.RemindMeLaterButton}`);
            remindMeLaterButton.addEventListener('click', this.onRemindMeLater.bind(this));
            this.subscriber.subscribe(loyaltyJoinTopic, QOSLevels.QOS0, this.onLoyaltyJoined.bind(this));
        });
    }
    show() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isActivated || this.cookieService.getPersistent().getCookie(this.options.hideCookieName).value) {
                return;
            }
            yield this.modal.show(true);
            // The container element that Modal creates has padding we need to remove.
            const modalElement = this.element.closest(`.${ModalClassNames.Default}`);
            modalElement.classList.add(ClassNames.ContainerOverride);
            this.trackImpression();
        });
    }
    hide() {
        this.modal.hide();
    }
    onCtaClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isActivated) {
                this.hide();
                return;
            }
            if (!this.options.isLoggedIn) {
                const params = new URLSearchParams({
                    action_source: name,
                    eor: '0',
                    url: window.location.pathname + window.location.search,
                });
                Redirector.redirect(`/forums/register.php?${params}`);
                return;
            }
            yield ButtonLoader.showLoader(this.ctaButton);
            yield joinLoyalty(activationSource);
            ButtonLoader.showContents(this.ctaButton);
        });
    }
    onLoyaltyJoined() {
        this.isActivated = true;
        const heroImage = this.element.querySelector(`.${ClassNames.HeroImage}`);
        heroImage.src = activatedImgSrc;
        heroImage.classList.add(ClassNames.ActivatedImage);
        const headerText = this.element.querySelector(`.${ClassNames.HeaderText}`);
        headerText.textContent = activatedHeaderText;
        const bodyText = this.element.querySelector(`.${ClassNames.BodyText}`);
        bodyText.textContent = activatedBodyText;
        this.ctaButton.textContent = activatedButtonText;
        const learnMoreLink = this.element.querySelector(`.${ClassNames.LearnMoreLink}`);
        learnMoreLink.remove();
        const remindMeLaterButton = this.element.querySelector(`.${ClassNames.RemindMeLaterButton}`);
        remindMeLaterButton.remove();
    }
    onRemindMeLater() {
        this.hide();
        this.cookieService.getPersistent().setCookie({
            name: this.options.hideCookieName,
            value: 1,
        });
    }
    trackImpression() {
        this.heap.track('Cashback Tag Activation Modal - Impression').catch(() => { });
    }
    static createFromDataAttributes(element) {
        const options = {
            id: element.id,
            isLoggedIn: element.dataset.isLoggedIn === '1',
            hideCookieName: element.dataset.hideCookieName,
        };
        return new CashbackTagActivationModal_1(new CookieService(), new Heap(), Injector.get(jobSubscriberName, InjectorScope.Singleton), options);
    }
};
CashbackTagActivationModal = CashbackTagActivationModal_1 = __decorate([
    injectable(name, dependencies),
    catalogItem(name, {
        instantiate: {
            fromDom: true,
            fromMutation: true,
            fromHydrationQueue: false,
        },
    })
], CashbackTagActivationModal);
export default CashbackTagActivationModal;
