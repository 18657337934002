var CashbackActivatorInterstitialContextService_1;
import { __awaiter, __decorate } from "tslib";
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import { removeAllChildren } from '@slickdeals/durango/dist/javascript/utilities/dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import JsxComponent from '@slickdeals/blueprint-twig/javascript/core/jsxComponent';
import AdBlockerDetectionService from '../../utilities/adBlockerDetectionService';
// Old interstitial states, will be replaced with new after RFA launch
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/extensions
import CashbackInterstitialSuccess from './_cashbackInterstitialSuccess';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/extensions
import CashbackInterstitialFailure from './_cashbackInterstitialFailure';
// New interstitial states, will replace old after RFA launch
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/extensions
import RFAInterstitialSuccess from './_rfaInterstitialSuccess';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/extensions
import RFAInterstitialFailure from './_rfaInterstitialFailure';
export const name = 'CashbackActivatorInterstitialContextService';
let CashbackActivatorInterstitialContextService = CashbackActivatorInterstitialContextService_1 = class CashbackActivatorInterstitialContextService {
    constructor() {
        this.isRewardsForAllFlow = false;
    }
    initInterstitialModalContext(data, heap, isRewardsForAllFlow = false) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            this.data = data;
            this.heap = heap;
            this.element = (JsxComponent.parseJsx("div", null));
            this.isRewardsForAllFlow = isRewardsForAllFlow;
            if (this.isRewardsForAllFlow) {
                if (!this.data.disableAdblockSetting && ((_a = this.data) === null || _a === void 0 ? void 0 : _a.isUsingAdblock)) {
                    this.failureState();
                }
                else if (!this.data.disableActivatedSetting) {
                    this.successState();
                }
            }
            else {
                /** Detect if adblock is enabled */
                const isUsingAdblock = ((_b = this.data) === null || _b === void 0 ? void 0 : _b.isUsingAdblock) || (yield AdBlockerDetectionService.isAffiliateRequestsBlocked());
                /** Render failure or success interstitial state depending on adblock detection */
                if (isUsingAdblock) {
                    this.failureState();
                    return this.element;
                }
                this.successState();
            }
            return this.element;
        });
    }
    /**
     * Renders the success state for interstitial
     */
    successState() {
        /** Fire heap impression */
        this.fireHeapEvent('Success');
        /** Refresh container with success state UI */
        if (!this.isRewardsForAllFlow) {
            this.refreshContext(JsxComponent.parseJsx(CashbackInterstitialSuccess, Object.assign({}, this.data)));
        }
        else if ((this.isRewardsForAllFlow && !this.data.disableActivatedSetting)) {
            this.refreshContext(JsxComponent.parseJsx(RFAInterstitialSuccess, Object.assign({}, this.data)));
        }
    }
    /**
     * Renders the failure state for interstitial
     */
    failureState() {
        /** Fire heap impression */
        this.fireHeapEvent('Failure');
        /** Refresh container with failure state UI */
        if (!this.isRewardsForAllFlow) {
            this.refreshContext(JsxComponent.parseJsx(CashbackInterstitialFailure, Object.assign({}, this.data)));
        }
        else if (this.isRewardsForAllFlow && !this.data.disableAdblockSetting) {
            this.refreshContext(JsxComponent.parseJsx(RFAInterstitialFailure, Object.assign({}, this.data)));
            /** Hit endpoint to update where user saw adblocker notification */
            CashbackActivatorInterstitialContextService_1.registerAdblockShown(this.data);
        }
    }
    /** Helper function used to refresh root <div> container */
    refreshContext(content) {
        removeAllChildren(this.element);
        this.element.append(content);
    }
    /**
     * Helper function used to fire heap events
     * @param state
     */
    fireHeapEvent(state) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.heap.track(`Rewards For All - ${state} Interstitial - Impression`);
            }
            catch (e) {
                Logger.warn('Error firing heap event');
            }
        });
    }
    static registerAdblockShown(data) {
        /** Hit endpoint to update where user saw adblocker notification */
        fetch('/cashback/ajax/adblock-shown/', {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
            },
            'body': JSON.stringify({
                'url': data.outclickHref,
                'threadId': data.threadId,
                'storeId': data.outclickStoreId,
            }),
        })
            .then((response) => response.json())
            .catch((error) => console.log('Error', error));
    }
};
CashbackActivatorInterstitialContextService = CashbackActivatorInterstitialContextService_1 = __decorate([
    injectable(name)
], CashbackActivatorInterstitialContextService);
export default CashbackActivatorInterstitialContextService;
