import { __awaiter } from "tslib";
import { name as JobRegistryName } from '@slickdeals/blueprint-twig/javascript/queues/jobQueue/jobRegistry';
import { JobContext, generateTopic, ExecutionFrequencies } from '@slickdeals/blueprint-twig/javascript/queues/jobContext';
import Injector, { InjectorScope } from '@slickdeals/durango/dist/javascript/core/injector';
import Modal from '@slickdeals/blueprint-twig/javascript/components/modal';
import Redirector from '@slickdeals/durango/dist/javascript/utilities/redirector';
/* eslint-disable import/extensions */
import CashbackActivatorInterstitialContextService from '../patterns/cashbackActivatorInterstitial/cashbackActivatorInterstitialContextService';
import AdBlockerDetectionService from './adBlockerDetectionService';
import { ClassNames as RfaInterstitialFailureClassNames, DataRoleNames as RfaInterstitialFailureDataRoleNames } from '../patterns/cashbackActivatorInterstitial/_rfaInterstitialFailure';
import { ClassNames as RfaInterstitialSuccessClassNames } from '../patterns/cashbackActivatorInterstitial/_rfaInterstitialSuccess';
/* eslint-enable import/extensions */
import Heap from '../heap/heap';
export const loyaltyJoinTopic = generateTopic('userJoinedLoyalty');
const registry = Injector.get(JobRegistryName, InjectorScope.Singleton);
// Note: key, in key/value pair to match that of entrypoint (ex. dealDetails matches the entrypoint ts file for deal details page)
export const outclickSelectorRegistry = {
    dealDetails: ['[data-store-id][data-store-slug]'],
    loyaltyTerms: ['[data-store-id][data-store-slug]'],
};
const registerJob = () => {
    const job = new JobContext({
        topic: loyaltyJoinTopic,
        durable: false,
        schedule: [
            {
                replays: 1,
                executionFrequency: ExecutionFrequencies.EverySession,
            },
        ],
    });
    registry.add(job);
};
export const joinLoyalty = (source) => fetch('/cashback/join/', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ source }),
})
    .then((response) => __awaiter(void 0, void 0, void 0, function* () {
    const responseJson = yield response.json();
    if (response === null || response === void 0 ? void 0 : response.ok) {
        registerJob();
        return responseJson;
    }
    return { isLoyaltyUser: false };
}))
    .catch(() => ({ isLoyaltyUser: false }));
/**
 * Updates user settings with the provided source and endpoint
 * @param source string representing source of click
 * @param endpoint endpoint to send data to
 */
export const registerInterstitialDimissal = (endpoint, value) => fetch(endpoint, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ value }),
})
    .then((response) => __awaiter(void 0, void 0, void 0, function* () {
    const responseJson = yield response.json();
    if (response === null || response === void 0 ? void 0 : response.ok) {
        return responseJson.success;
    }
    return false;
}))
    .catch(error => console.log('Error', error));
/**
 * Queries page for links and buttons that contain the [data-product-exitwebsite] attribute
 *
 * @returns An array of buttons and/or links that meet query criteria
 */
const getOutclickLinks = (selectors) => {
    const outclickElements = [];
    selectors.forEach(selector => {
        outclickElements.push(...Array.from(document.querySelectorAll(selector)));
    });
    return outclickElements;
};
/**
 * Hooks up the interstitial flow to a given element. Interstitial flow includes the success (no adblock) or failure (adblock enabled) flows.
 *
 * @param element Element to bind event on
 * @param outclickData Object of data associated with outclickElement
 */
const registerInterstitialFlow = (element, outclickData) => {
    // eslint-disable-next-line consistent-return
    element.addEventListener('click', (e) => __awaiter(void 0, void 0, void 0, function* () {
        // Body element used to reference data attributes for page level data, used for outclick interstitials
        const bodyElement = document.querySelector('body');
        // Get data attributes, collect settings
        const showCashbackInterstitial = parseInt(bodyElement.dataset.showCashbackInterstitial, 10) === 1;
        // if show cb interstitial is disabled, return and cancel intersitial flow
        if (!showCashbackInterstitial) {
            return true;
        }
        e.preventDefault();
        const disableActivatedSetting = parseInt(bodyElement.dataset.disableCbActivatedNotification, 10) === 1;
        const disableAdblockSetting = parseInt(bodyElement.dataset.disableCbAdblockActivatedNotification, 10) === 1;
        const { threadId } = bodyElement.dataset;
        // NOTE: Handles a safari ios issue for the open() function. Essentially, we need to set a reference for a new tab before an async call, and update it's href later based on user settings.
        let winRef;
        if (disableActivatedSetting || disableAdblockSetting) {
            winRef = window.open();
        }
        // Collect user interstitial settings to pass to service
        const userInterstitialSettings = {
            disableActivatedSetting,
            disableAdblockSetting,
            threadId,
        };
        // Create new instance of service to build out success/failure interstitial
        const interstitialService = new CashbackActivatorInterstitialContextService();
        const isUsingAdblock = yield AdBlockerDetectionService.isAffiliateRequestsBlocked();
        if (isUsingAdblock && !disableAdblockSetting) {
            // if adblock interstitial is not disabled in user settings, create and show
            const adblockInterstitialContent = yield interstitialService.initInterstitialModalContext(Object.assign(Object.assign(Object.assign({}, outclickData), userInterstitialSettings), { isUsingAdblock }), new Heap(), true);
            // Create new instance of modal with contents from context service
            const adblockInterstitialModal = new Modal({
                content: adblockInterstitialContent,
                isFixed: true,
                closeButton: false,
            });
            // Show modal on screen
            adblockInterstitialModal.show(true);
            // Configure check box on failure interstitial
            const dismissFailureCheckbox = adblockInterstitialContent.querySelector(`.${RfaInterstitialFailureClassNames.Checkbox}`);
            if (dismissFailureCheckbox) {
                dismissFailureCheckbox.addEventListener('change', (event) => __awaiter(void 0, void 0, void 0, function* () {
                    const checkbox = event.target;
                    checkbox.dataset.checked = checkbox.checked ? '1' : '0';
                    const result = yield registerInterstitialDimissal('/cashback/ajax/updateAdblockInterstitial/', checkbox.checked);
                    if (result) {
                        const bodyElm = document.querySelector('body');
                        bodyElm.dataset.disableCbAdblockActivatedNotification = checkbox.checked ? '1' : '0';
                        bodyElm.dataset.showCashbackInterstitial = (parseInt(bodyElm.dataset.showCashbackInterstitial, 10) && (!parseInt(bodyElm.dataset.disableCbActivatedNotification, 10) || !parseInt(bodyElm.dataset.disableCbAdblockActivatedNotification, 10))) ? '1' : '0';
                    }
                }));
            }
            // Configure close button on failure interstitial
            const closeButton = adblockInterstitialContent.querySelector(`.${RfaInterstitialFailureClassNames.ReturnButton}`);
            if (closeButton) {
                closeButton.setAttribute('data-role', RfaInterstitialFailureDataRoleNames.CloseButton);
                closeButton.addEventListener('click', () => adblockInterstitialModal.hide());
            }
            // Configure proceed button on failure interstitial
            const proceedButton = adblockInterstitialContent.querySelector(`.${RfaInterstitialFailureClassNames.ProceedLink}`);
            if (proceedButton) {
                proceedButton.setAttribute('data-role', RfaInterstitialFailureDataRoleNames.ProceedButton);
                proceedButton.addEventListener('click', () => Redirector.redirect(proceedButton.dataset.href));
            }
        }
        else if (!disableActivatedSetting) {
            // If activated interstitial is not disabled, render it in a modal
            const activatedInterstitialContent = yield interstitialService.initInterstitialModalContext(Object.assign(Object.assign({}, outclickData), userInterstitialSettings), new Heap(), true);
            // Create new instance of modal with contents from context service
            const interstitialModal = new Modal({
                content: activatedInterstitialContent,
                isFixed: true,
                closeButton: false,
            });
            // Show modal on screen
            interstitialModal.show(true);
            // Configure check box on success interstitial
            const dismissSuccessCheckbox = activatedInterstitialContent.querySelector(`.${RfaInterstitialSuccessClassNames.Checkbox}`);
            if (dismissSuccessCheckbox) {
                dismissSuccessCheckbox.addEventListener('change', (event) => __awaiter(void 0, void 0, void 0, function* () {
                    const checkbox = event.target;
                    checkbox.dataset.checked = checkbox.checked ? '1' : '0';
                    const result = yield registerInterstitialDimissal('/cashback/ajax/updateSuccessInterstitial/', checkbox.checked);
                    if (result) {
                        const bodyElm = document.querySelector('body');
                        bodyElm.dataset.disableCbActivatedNotification = checkbox.checked ? '1' : '0';
                        bodyElm.dataset.showCashbackInterstitial = (parseInt(bodyElm.dataset.showCashbackInterstitial, 10) && (!parseInt(bodyElm.dataset.disableCbActivatedNotification, 10) || !parseInt(bodyElm.dataset.disableCbAdblockActivatedNotification, 10))) ? '1' : '0';
                    }
                }));
            }
            // Handle 3 sec countdown on success intersitial then update merchant in new tab, close interstitial
            let countdown = 3;
            const redirectDelay = setInterval(() => {
                if (countdown < 0) {
                    clearInterval(redirectDelay);
                    // Open new tab with merchant
                    Redirector.redirect(outclickData.outclickHref);
                }
                else {
                    document.querySelector(`.${RfaInterstitialSuccessClassNames.CountdownValue}`).textContent = `${countdown}`;
                }
                countdown -= 1;
            }, 1000);
        }
        else {
            // Redirector.open(outclickData.outclickHref);
            winRef.location.href = outclickData.outclickHref;
        }
    }));
};
/**
 * Used to return a type predicate: https://www.typescriptlang.org/docs/handbook/advanced-types.html#using-type-predicates
 *
 * @param element Element to check
 * @returns boolean
 */
const isOutclickLink = (element) => element.href !== undefined;
/**
 * Initialize cashback interstitial flow on outclicks
 */
export const initCashbackInterstitial = (selectors) => {
    const allOutclicks = getOutclickLinks(selectors);
    allOutclicks.forEach(outclickElement => {
        const outclickData = {
            outclickHref: isOutclickLink(outclickElement) ? outclickElement.href : outclickElement.dataset.href,
            outclickStoreId: outclickElement.dataset.storeId,
            outclickStoreSlug: outclickElement.dataset.storeSlug,
        };
        registerInterstitialFlow(outclickElement, outclickData);
    });
};
