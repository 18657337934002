import JsxComponent from '@slickdeals/blueprint-twig/javascript/core/jsxComponent';
import Icon from '@slickdeals/blueprint-twig/javascript/components/icon';
import { classNameWithModifier } from '@slickdeals/durango/dist/javascript/utilities/css';
import { modifiers as ButtonModifiers, ClassNames as ButtonClassNames } from '@slickdeals/blueprint-twig/javascript/components/button';
import '@slickdeals/blueprint-twig/javascript/components/link';
import '../../../scss/components/pagination.scss';
export const name = 'Pagination';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-c-pagination";
    ClassNames["JavaScript"] = "js-pagination";
    ClassNames["Wrapper"] = "bp-c-pagination_wrapper";
    ClassNames["Ends"] = "bp-c-pagination_ends";
    ClassNames["EndsDisabled"] = "bp-c-pagination_ends--disabled";
    ClassNames["LeftEnd"] = "bp-c-pagination_left";
    ClassNames["LeftPrev"] = "bp-c-pagination_prev";
    ClassNames["LeftStart"] = "bp-c-pagination_start";
    ClassNames["RightEnd"] = "bp-c-pagination_right";
    ClassNames["RightNext"] = "bp-c-pagination_next";
    ClassNames["RightLast"] = "bp-c-pagination_last";
    ClassNames["Page"] = "bp-c-pagination_link";
    ClassNames["PageActive"] = "bp-c-pagination_link--active";
})(ClassNames || (ClassNames = {}));
export default class Pagination extends JsxComponent {
    constructor() {
        super(name);
    }
    createElement(props) {
        this.pagesToShow = props.pagesToShow;
        const previous = this.createPrevArrows(props.currentPage);
        const next = this.createNextArrows(props.currentPage, props.numOfPages);
        const pages = this.createButtonList(props.currentPage, props.numOfPages, props.isMobileExperience);
        return (JsxComponent.parseJsx("fragment", null,
            previous,
            pages,
            next));
    }
    createPrevArrows(currentPage) {
        const classNames = [ClassNames.Wrapper, ClassNames.Ends];
        const buttonClassNames = [ClassNames.LeftEnd, classNameWithModifier(ButtonClassNames.Default, ButtonModifiers.unstyled)];
        if (currentPage === 1) {
            classNames.push(ClassNames.EndsDisabled);
        }
        return (JsxComponent.parseJsx("fragment", null,
            JsxComponent.parseJsx("div", { className: classNames.join(' ') },
                JsxComponent.parseJsx("button", { className: `${buttonClassNames.join(' ')} ${ClassNames.LeftStart}`, "aria-label": "first", "data-page": "first" },
                    Icon.createElement({ 'name': 'minusLeft' }),
                    Icon.createElement({ 'name': 'arrowLeft' }))),
            JsxComponent.parseJsx("div", { className: classNames.join(' ') },
                JsxComponent.parseJsx("button", { className: `${buttonClassNames.join(' ')} ${ClassNames.LeftPrev}`, "aria-label": "previous", "data-page": "previous" }, Icon.createElement({ 'name': 'arrowLeft' })))));
    }
    createNextArrows(currentPage, numberOfPages) {
        const classNames = [ClassNames.Wrapper, ClassNames.Ends];
        const buttonClassNames = [ClassNames.RightEnd, classNameWithModifier(ButtonClassNames.Default, ButtonModifiers.unstyled)];
        if (currentPage === numberOfPages || numberOfPages === 0) {
            classNames.push(ClassNames.EndsDisabled);
        }
        return (JsxComponent.parseJsx("fragment", null,
            JsxComponent.parseJsx("div", { className: classNames.join(' ') },
                JsxComponent.parseJsx("button", { className: `${buttonClassNames.join(' ')} ${ClassNames.RightNext}`, "aria-label": "next", "data-role": "pagination", "data-page": "next" }, Icon.createElement({ 'name': 'arrowRight' }))),
            JsxComponent.parseJsx("div", { className: classNames.join(' ') },
                JsxComponent.parseJsx("button", { className: `${buttonClassNames.join(' ')} ${ClassNames.RightLast}`, "aria-label": "last", "data-role": "pagination", "data-page": "last" },
                    Icon.createElement({ 'name': 'arrowRight' }),
                    Icon.createElement({ 'name': 'minusRight' })))));
    }
    createButtonList(currentPage, numberOfPages, isMobileExperience = false) {
        let pageButtons;
        // set pages
        if (numberOfPages > this.pagesToShow + 2) {
            pageButtons = this.createTruncatedList(currentPage, numberOfPages, isMobileExperience);
        }
        else {
            pageButtons = this.createFullList(currentPage, numberOfPages);
        }
        return (JsxComponent.parseJsx("fragment", null, pageButtons));
    }
    createFullList(currentPage, numberOfPages) {
        const pages = this.createPageButtons(currentPage, 1, numberOfPages);
        return (JsxComponent.parseJsx("fragment", null, pages));
    }
    createTruncatedList(currentPage, numberOfPages, isMobileExperience) {
        const elementsToShow = [];
        let pages;
        if (currentPage < Math.ceil(this.pagesToShow / 2)) {
            pages = this.createPageButtons(currentPage, 1, this.pagesToShow);
        }
        else if (currentPage > numberOfPages - Math.ceil(this.pagesToShow / 2)) {
            pages = this.createPageButtons(currentPage, numberOfPages - (this.pagesToShow - 1), numberOfPages);
        }
        else {
            pages = this.createPageButtons(currentPage, currentPage - Math.floor(this.pagesToShow / 2), currentPage + Math.floor(this.pagesToShow / 2));
        }
        elementsToShow.push(pages);
        // Only set up ellipses and last page button on desktop experience
        if (!isMobileExperience) {
            // set ellipses
            if (currentPage < (numberOfPages - Math.ceil(this.pagesToShow / 2))) {
                elementsToShow.push(this.createEllipsesButton());
            }
            // set last page
            if (currentPage < (numberOfPages - Math.floor(this.pagesToShow / 2))) {
                elementsToShow.push(this.createLastPageButton(numberOfPages));
            }
        }
        return (JsxComponent.parseJsx("fragment", null, elementsToShow));
    }
    createPageButtons(currentPage, start, end) {
        const pages = [];
        for (let i = start; i <= end; i += 1) {
            const buttonClasses = [ClassNames.Page, classNameWithModifier(ButtonClassNames.Default, ButtonModifiers.unstyled)];
            if (currentPage === i) {
                buttonClasses.push(ClassNames.PageActive);
            }
            const page = (JsxComponent.parseJsx("div", { className: ClassNames.Wrapper },
                JsxComponent.parseJsx("button", { className: buttonClasses.join(' '), "data-role": "pagination", "data-page": `${i}` }, i)));
            pages.push(page);
        }
        return (JsxComponent.parseJsx("fragment", null, pages));
    }
    createEllipsesButton() {
        const buttonClasses = [ClassNames.PageActive, classNameWithModifier(ButtonClassNames.Default, ButtonModifiers.unstyled)];
        return (JsxComponent.parseJsx("div", { className: ClassNames.Wrapper },
            JsxComponent.parseJsx("button", { className: buttonClasses.join(' ') }, "...")));
    }
    createLastPageButton(numberOfPages) {
        const buttonClasses = [ClassNames.Page, classNameWithModifier(ButtonClassNames.Default, ButtonModifiers.unstyled)];
        return (JsxComponent.parseJsx("div", { className: ClassNames.Wrapper },
            JsxComponent.parseJsx("button", { className: buttonClasses.join(' '), "data-role": "pagination", "data-page": `${numberOfPages}` }, numberOfPages)));
    }
}
