import JsxComponent from '@slickdeals/blueprint-twig/javascript/core/jsxComponent';
import Redirector from '@slickdeals/durango/dist/javascript/utilities/redirector';
export const name = 'CashbackInterstitialSuccess';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-interstitialSuccess";
    ClassNames["Javascript"] = "js-interstitialSuccess";
    ClassNames["CountdownValue"] = "bp-p-interstitialSuccess_infoCountdownValue";
})(ClassNames || (ClassNames = {}));
class CashbackInterstitialSuccess extends JsxComponent {
    constructor() {
        super(name);
    }
    /**
     * Creates the inner parts of the success interstitial provided the data
     *
     * @param props Data used to render element
     * @returns HTMLDivElement The inner components for success interstitial
     */
    createElement(props) {
        const { merchantStoreLogo, merchantLink, cashbackRate } = props;
        const link = merchantLink.replace(/&amp;/g, '&');
        // Runs a simple countdown timer before redirecting user to merchant link
        let countdown = 3;
        const redirectDelay = setInterval(() => {
            if (countdown < 0) {
                clearInterval(redirectDelay);
                Redirector.redirect(link);
                // NOTE: Commented out while we figure out how to proceed with Product/Design.
                // const hideModalEvent = new CustomEvent("hideSuccessEventModal");
                // document.dispatchEvent(hideModalEvent);
            }
            else {
                document.querySelector(`.${ClassNames.CountdownValue}`).innerHTML = `${countdown}`;
            }
            countdown -= 1;
        }, 1000);
        // Sets style overrides to be used for the success interstitial
        this.interstitialContainerStylesOverride();
        const interstitialSuccessClassNames = [ClassNames.Default, ClassNames.Javascript].join(' ');
        return (JsxComponent.parseJsx("div", { className: interstitialSuccessClassNames },
            JsxComponent.parseJsx("img", { src: "/image-pool/sd-branding/sd-logo-full-blueberry.svg", className: "bp-p-interstitialSuccess_logo" }),
            JsxComponent.parseJsx("img", { src: merchantStoreLogo, alt: "Store logo", className: "bp-p-interstitialSuccess_storeLogo" }),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialSuccess_subhead" }, "Congratulations! You're about to get paid to shop."),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialSuccess_headline" },
                cashbackRate,
                " Cashback Activating"),
            JsxComponent.parseJsx("img", { src: "/image-pool/loyalty/no-extension/CoinDropAnimation.gif", className: "bp-p-interstitialSuccess_img" }),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialSuccess_info" },
                JsxComponent.parseJsx("p", { className: "bp-p-interstitialSuccess_infoCountdown" },
                    "Redirecting in ",
                    JsxComponent.parseJsx("span", { className: ClassNames.CountdownValue }, "3"),
                    " seconds..."))));
    }
    /**
     * Creates a style element used to override generic modal container styles. This is a temp solution and will need to be re-worked in a follow up ticket.
     */
    interstitialContainerStylesOverride() {
        const styles = document.createElement('style');
        styles.id = 'styleBlockOverride';
        const content = `
      .bp-c-popup {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        max-height: 100vh !important;
        border-radius: 0 !important;
        transform: translate(0, 0) !important;
      }
    `;
        styles.innerHTML = content;
        document.body.append(styles);
    }
}
export default CashbackInterstitialSuccess;
