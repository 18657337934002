import JsxComponent from '@slickdeals/blueprint-twig/javascript/core/jsxComponent';
export const name = 'CashbackInterstitialFailure';
export var ClassNames;
(function (ClassNames) {
    ClassNames["Default"] = "bp-p-cashbackInterstitialFailure";
    ClassNames["Javascript"] = "js-cashbackInterstitialFailure";
    ClassNames["ReturnButton"] = "bp-p-interstitialFailure_button";
    ClassNames["ProceedLink"] = "bp-p-interstitialFailure_proceedLink";
    ClassNames["Checkbox"] = "bp-p-interstitialFailure_checkbox";
})(ClassNames || (ClassNames = {}));
export var DataRoleNames;
(function (DataRoleNames) {
    DataRoleNames["CloseButton"] = "returnToDealDetailsFromInterstitial";
    DataRoleNames["ProceedButton"] = "proceedLinkFromInterstitial";
})(DataRoleNames || (DataRoleNames = {}));
class RFAInterstitialFailure extends JsxComponent {
    constructor() {
        super(name);
    }
    /**
     * Creates the inner parts of the failure interstitial provided the data
     *
     * @param props Data used to render element
     * @returns HTMLDivElement The inner components for failure interstitial
     */
    createElement(props) {
        const { outclickHref } = props;
        const link = outclickHref.replace(/&amp;/g, '&');
        this.interstitialContainerStylesOverride();
        return (JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure js-interstitialFailure" },
            JsxComponent.parseJsx("img", { src: "/image-pool/sd-branding/sd-logo-full-blueberry.svg", className: "bp-p-interstitialFailure_logo" }),
            JsxComponent.parseJsx("img", { className: "bp-p-interstitialFailure_slicky", src: "https://slickdeals.net/lp/wp-content/uploads/rewards/slicky-cashback-blocked.svg" }),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_main" },
                JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_heading" }, "Cashback Blocked"),
                JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_subheading--desktop" }, "Oh no! Adblock Detected."),
                JsxComponent.parseJsx("p", { className: "bp-p-interstitialFailure_adBlockMessage" }, "With an ad block on, Slickdeals doesn't know who you are in order to give you Cashback Rewards."),
                JsxComponent.parseJsx("p", { className: "bp-p-interstitialFailure_adBlockFaqMessage--mobile" }, "It's an easy fix! Simply whitelist Slickdeals as the destination merchant."),
                JsxComponent.parseJsx("p", { className: "bp-p-interstitialFailure_adBlockFaqMessage--desktop" },
                    "It's an easy fix! Simply whitelist Slickdeals as the destination merchant. Every ad block is different. For specific details on your ad block or if you're still receiving this ad block after whitelisting, ",
                    JsxComponent.parseJsx("a", { className: "bp-p-interstitialFailure_adBlockFaqLink", "data-role": "cashbackAdblockFaqDesktop", href: "https://help.slickdeals.net/hc/en-us/articles/1260800863609-How-to-Guarantee-Cashback-is-Tracked-when-Running-Ad-Blockers", target: "_blank" }, "click here"),
                    ".")),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_info" },
                JsxComponent.parseJsx("button", { className: ClassNames.ReturnButton }, "Go Back"),
                JsxComponent.parseJsx("button", { className: ClassNames.ProceedLink, "data-href": link }, "Proceed without Cashback")),
            JsxComponent.parseJsx("p", { className: "bp-p-interstitialFailure_adBlockFaqMessage--mobile" },
                "Every ad block is different. For specific details on your ad block or if you're still receiving this ad block after whitelisting, ",
                JsxComponent.parseJsx("a", { className: "bp-p-interstitialFailure_adBlockFaqLink", "data-role": "cashbackAdblockFaqMobile", href: "https://help.slickdeals.net/hc/en-us/articles/1260800863609-How-to-Guarantee-Cashback-is-Tracked-when-Running-Ad-Blockers", target: "_blank" }, "click here"),
                "."),
            JsxComponent.parseJsx("div", { className: "bp-p-interstitialFailure_footer" },
                JsxComponent.parseJsx("input", { className: "bp-p-interstitialFailure_checkbox", "data-role": "disableAdblockInterstitialCheckbox", type: "checkbox" }),
                JsxComponent.parseJsx("p", { className: "bp-p-interstitialFailure_checkboxMessage" }, "Don't show this message again."))));
    }
    /**
     * Creates a style element used to override generic modal container styles. This is a temp solution and will need to be re-worked in a follow up ticket.
     */
    interstitialContainerStylesOverride() {
        const styles = document.createElement('style');
        styles.id = 'styleBlockOverride';
        const content = `
      .bp-c-popup {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        max-height: 100vh !important;
        border-radius: 0 !important;
        transform: translate(0, 0) !important;
      }
    `;
        styles.innerHTML = content;
        document.body.append(styles);
    }
}
export default RFAInterstitialFailure;
