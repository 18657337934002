import { __decorate } from "tslib";
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import JsxComponent from '@slickdeals/blueprint-twig/javascript/core/jsxComponent';
import Pagination, { ClassNames as PaginationClassNames } from './pagination';
import { removeAllChildren } from '@slickdeals/durango/dist/javascript/utilities/dom';
export const name = 'PaginationContextService';
let PaginationContextService = class PaginationContextService {
    initContext(options) {
        this.element = (JsxComponent.parseJsx("div", { className: PaginationClassNames.Default }));
        this.refreshPagination(options);
        return this.element;
    }
    /**
     * Refreshes the context with passed content
     *
     * @param {HTMLElement} content Content to refresh the context with
     */
    refreshPagination(options) {
        const content = (JsxComponent.parseJsx(Pagination, Object.assign({}, options)));
        this.refreshContext(content);
    }
    /**
     * Refreshes the context with passed content
     *
     * @param {HTMLElement} content Content to refresh the context with
     */
    refreshContext(content) {
        removeAllChildren(this.element);
        this.element.appendChild(content);
    }
};
PaginationContextService = __decorate([
    injectable(name)
], PaginationContextService);
export default PaginationContextService;
